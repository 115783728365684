<template>
    <div>
        <div class="contract-infos">
            <div class="title">
                <h1>
                    <img src="@/assets/images/agreement.png" alt="img" />
                    CONTRATO #{{ contract.id }}
                    <a-popconfirm
                        v-if="contract.status == 'concluded' && $root.isAdmin()"
                        placement="bottom"
                        ok-text="Sim"
                        cancel-text="Não"
                        @confirm="allowUserToEdit"
                    >
                        <template slot="title">
                            <div class="f12">
                                Deseja liberar edição do contrato<br />
                                para <b>{{ contract.user.first_name }}</b> por
                                20 minutos?
                            </div>
                        </template>
                        <a-icon
                            v-if="!alowEditLoading"
                            class="edit c-pointer"
                            type="edit"
                        />
                        <a-icon
                            v-if="alowEditLoading"
                            class="edit"
                            type="loading"
                        />
                    </a-popconfirm>
                </h1>
            </div>
            <a-row
              v-if="contract.created"
              class="meta"
              align="middle"
              type="flex"
              justify="start"
            >
              <a-col>
                <a
                  class="user dotted-phrase"
                  @click="$router.push(`/users/view/${contract.user.id}`)"
                  v-if="contract.user != undefined"
                  target="_blank"
                  style=""
                >
                  <a-tooltip placement="right">
                    <template slot="title">
                      {{ contract.user.first_name }}
                      {{ contract.user.last_name }}
                    </template>
                    <a-avatar
                      v-if="contract.user.avatar"
                      :src="contract.user.avatar"
                      :size="20"
                    />
                    <a-avatar
                      v-else
                      :size="20"
                      class="upper"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ contract.user.first_name.substring(0, 1)
                      }}{{ contract.user.last_name.substring(0, 1) }}
                    </a-avatar>
                  </a-tooltip>
                </a>
              </a-col>
              <a-col>
                <a-divider type="vertical" />
                <a-icon class="cprimary" type="calendar" />
                {{
                  contract.created != undefined
                    ? formatDateTime(contract.created)
                    : ""
                }}
              </a-col>

              <a-col>
                <a-divider type="vertical" />
                <a-icon class="cprimary" type="tag" />
                {{ formatContractStatus(contract.status) }}</a-col
              >
            </a-row>

        </div>
    </div>
</template>

<script>
import { addMinutes } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ViewContractDataSection",
    props: {
        contract: Object,
    },
    data() {
        return {
            alowEditLoading: false,
        };
    },
    mixins: [formatThings],
    methods: {
        allowUserToEdit() {
            this.alowEditLoading = true;

            let updtContract = {
                    id: this.contract.id,
                    customer_id: this.contract.customer.id,
                    company_id: this.contract.company_id,
                    company_branch_id: this.contract.company_branch_id,
                    id_infotera: this.contract.id_infotera,
                    user: this.contract.user.id,
                    company: this.contract.company,
                    value: this.contract.value,
                    contracted_services: this.contract.contracted_services,
                    release_date: this.contract.release_date,
                    status: this.contract.status,
                },
                expiration = addMinutes(new Date(), 20);

            updtContract["allow_user_edit_expiration_time"] =
                expiration.getTime();
            updtContract["allow_user_edit_expiration_date_time"] =
                this.formatDateTimeObj(expiration);
            updtContract["allow_user_edit_expiration_minutes"] = 20;
            updtContract["allow_user_edit"] = true;

            updtContract["action"] = "update-user-permission";
            updtContract[
                "description"
            ] = `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> liberou a edição para o contrato <font class="id">ID ${this.contract.id}</font>. Clique nesta notificação para acessar.`;
            updtContract["modified_by"] = {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
            };

            this.$http
                .post("/contract/update", updtContract)
                .then(({ data }) => {
                    data;
                    this.alowEditLoading = false;
                    this.$message.success("Edição liberada com sucesso!");
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.alowEditLoading = false;
                });
        },
        formatStatus(status) {
            if (status === "new") {
                status = "Novo";
            }

            if (status === "concluded") {
                status = "Concluído";
            }

            if (status === "canceled") {
                status = "Cancelado";
            }

            if (status === "edit") {
                status = "Edição";
            }

            return status;
        },
    },
};
</script>

<style lang="sass" scoped>
.contract-infos
  .meta
    font-size: 9px
    line-height: 8px
    font-weight: 600
    text-transform: none
    margin-top: 8px
    a
      color: #292828
  .title
    border-bottom: 1px solid #eee
    padding: 0 0 10px
    h1
      font-weight: 600
      line-height: 1
      margin: 0
      letter-spacing: -2px
      position: relative
      .edit
        position: absolute
        top: 0
        right: 0
        color: #aaa
        font-size: 16px
</style>
