<template>
  <div>
    <vue-editor
      v-model="content"
      placeholder="Digite o texto aqui..."
      :editor-toolbar="customToolbar"
      @text-change="updateElementContent"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: {
    content: String,
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        [
          { size: ["small", false, "large", "huge"] },
          {
            header: [1, 2, 3, 4, 5, 6],
          },
        ],

        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "link"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],

        ["clean"], // remove formatting button
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
      ],
    };
  },
  methods: {
    updateElementContent() {
      this.$emit("updateElementContent", this.content);
    },
  },
};
</script>
