<template>
  <div>
    <aAnchor @change="smoothScroll">
      <div class="title">Documentos</div>

      <draggable v-model="docsArr" @end="onEndDragOrderDocuments">
        <div v-for="(doc, index) in docsArr" :key="index">
          <aAnchorLink :href="`#doc-${doc.id}`">
            <template #title>
              <div class="dotted-phrase">
                <a-checkbox
                  class="mr-10"
                  v-model="doc.show"
                  style="font-size: 13px; font-weight: 500; position: relative"
                  @change="onCheckShowDocument"
                >
                </a-checkbox>
                {{ doc.id }} - {{ getDocNameById(doc.id) }}
              </div>
            </template>
          </aAnchorLink>
        </div>
      </draggable>
    </aAnchor>
  </div>
</template>

<script>
import jq from "jquery";
import draggable from "vuedraggable";
import documentMixins from "@/components/documents/mixins/documentMixins";
jq;
export default {
  name: "ViewContractDocNavSectionV2",
  props: {
    contract: Object,
    editDocuments: Boolean,
    documentsArr: Array,
    documentsList: Array,
  },
  components: {
    draggable,
  },
  mixins: [documentMixins],
  data() {
    return {
      docsArr: [],
    };
  },
  async beforeCreate() {},
  mounted() {
    this.docsArr = this.documentsArr;

    this.document.filters.showHtml = "no";
    this.document.pagination.perPage = 50;
    this.getDocuments();
  },
  created() {
    this.$on("openPasswordModal", this.handleEvent);
  },
  methods: {
    handleEvent() {
      this.showPasswordModal = true;
    },
    onEndDragOrderDocuments() {
      this.$emit("onEndDragOrderDocuments", this.docsArr);
    },
    onCheckShowDocument() {
      this.$emit("onCheckShowDocument", this.docsArr);
    },
    getDocNameById(id) {
      let theDoc = [];

      if (this.document.list.length)
        theDoc = this.document.list.filter((doc) => {
          return doc.id == id;
        });

      return theDoc.length ? theDoc[0].name : "Documento";
    },
    smoothScroll(e) {
      if (e) {
        let elementTop = document.getElementById(e.replace("#", "")).offsetTop;

        jq(".document-viewer").animate(
          {
            scrollTop: elementTop,
          },
          1000
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.dotted-phrase
  text-transform: uppercase
  font-size: 12px
  font-weight: 500
  width: 260px
  padding: 1px 0 0
.title
  padding: 0 0 0 16px
  font-weight: 500
  font-size: 10px
  color: #ccc
</style>
