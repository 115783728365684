<template>
  <div>
    <aRow class="customer" :class="!toggleCustomer ? 'collapse' : ''">
      <aCol class="title" :span="24">
        <aRow type="flex" justify="space-between">
          <aCol>
            <a-icon type="user" />
            Contratante
          </aCol>
          <aCol>
            <a-icon
              @click="onClickToggleCustomer"
              class="f12 c-pointer cgray"
              type="down"
            />
          </aCol>
        </aRow>
      </aCol>

      <aCol v-if="toggleCustomer" class="infos" :span="24">
        <span class="img">
          <a-icon type="user" />
        </span>
        {{ contract.customer.id ? contract.customer.id + " - " : "" }}
        <span v-if="contract.customer.person_type == 'Pessoa Jurídica'">
          {{ contract.customer.trading_name }}
        </span>
        <span v-else>
          {{ contract.customer.first_name }}
          {{ contract.customer.last_name }}
        </span>
      </aCol>
      <aCol v-if="toggleCustomer" class="infos" :span="24">
        <span class="img">
          <a-icon type="mobile" />
        </span>
        {{ contract.customer.mobile_phone }}
        {{ contract.customer.phone ? ` ou ${contract.customer.phone}` : "" }}
      </aCol>
      <aCol v-if="toggleCustomer" class="infos" :span="24">
        <span class="img">
          <a-icon type="mail" />
        </span>
        {{ contract.customer.email }}
      </aCol>
    </aRow>
  </div>
</template>

<script>
export default {
  name: "ViewContractCustomerDataSection",
  props: {
    contract: Object,
  },
  data() {
    return {
      toggleCustomer: false,
    };
  },
  methods: {
    onClickToggleCustomer() {
      if (this.toggleCustomer) {
        this.toggleCustomer = false;
      } else {
        this.toggleCustomer = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.customer.collapse
    .title
        margin: 0 0 -17px
        padding: 0 0 15px
.customer
  .infos
    font-weight: 600
    font-size: 13px
    padding: 3px 0
    .img
      width: 20px
      display: inline-block
      text-align: center
      i
        color: #aaa
  .title
    border-bottom: 1px solid #eee
    padding: 0 0 10px
    margin: 0 0 10px
    font-weight: 500
    i
      font-size: 16px
</style>
