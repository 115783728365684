<template>
  <div>----</div>
</template>

<script>
export default {
  props: {
    content: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    updateElementContent() {
      this.$emit("updateElementContent", this.content);
    },
  },
};
</script>
