import replaceOnce from "replace-once";
import { format } from "date-fns";

import formatThings from "@/mixins/general/formatThings";
import policyMixins from "@/mixins/policies/policyMixins";
import companyBankAccountsMixins from "@/mixins/company-bank-accounts/companyBankAccountsMixins.js";

export default {
  mixins: [formatThings, policyMixins, companyBankAccountsMixins],
  data() {
    return {
      banks: [],
      airlinesList: [],
      contrato: {
        cliente: {
          endereco: {},
        },
        empresa: "",
        pagamento: {
          credito: {
            ativo: false,
            valor: "",
            cartao: [],
          },
          transferencia: {
            ativo: false,
            valor: "",
            banco: "",
          },
          personalizado: {
            ativo: false,
            valor: "",
            nome: "",
            obs: "",
          },
          boleto: {
            ativo: false,
            valor: "",
            obs: "",
          },
          carta: {
            ativo: false,
            valor: "",
            id_voyage: "",
            lista: [],
          },
          link: {
            ativo: false,
            valor: "",
            lista: [],
          },
          terceiros: {
            ativo: false,
          },
          total: 0,
        },
        banks: [],
        oldBanks: [
          {
            name: "Banco Bradesco",
            number: 237,
            agency: "1246",
            account: "15600-0",
          },
          {
            name: "Banco Safra S.A",
            number: 422,
            agency: "0023",
            account: "395014-1",
          },
          {
            name: "Banco Cooperativo do Brasil",
            number: 756,
            agency: "3166",
            account: "50340-1",
          },
        ],
        viajantes: [],
        listaHoteis: [],
        aereo: {
          voos: [],
        },
        listaServicos: [],
        politicas: [],
        produtos: [],
        documentos: [],
      },
    };
  },
  beforeMount() {
    this.policy.filters.module = "contract";
    this.policy.filters.id = this.$route.params.id;
    this.policy.filters.showPolicy = 1;
    this.getPolicies();

    this.$http.get(`/airline/list?page=1&per_page=500`).then(({ data }) => {
      this.airlinesList = data.data;
    });
    this.companyBankAccounts.pagination.perPage = 100;
    this.getCompanyBankAccounts();
  },
  mounted() {},
  methods: {
    getBankDataByNumber(number) {
      const theBank = this.companyBankAccounts.list.filter(
        (bank) => bank.account === number
      );

      return theBank.length ? theBank[0] : {};
    },
    getCiaDataByName(name) {
      let theAirline = [];

      theAirline = this.airlinesList.filter((airline) => {
        return airline.name == name;
      });

      console.log("theAirline", theAirline[0]);

      return theAirline[0];
    },
    generateContractPtBr(contract) {
      console.log("COMEÇANDO ESTRUTURA DO CONTRATO...");

      this.contrato.id = parseInt(contract.id);

      this.contrato.empresa = contract.company_details
        ? contract.company_details.company_name
        : contract.company;
      this.contrato.filial = contract?.company_branch?.name;

      if (contract.customer.id == undefined) {
        this.contrato.cliente.pessoa = contract.person_type;
        this.contrato.cliente.nome = contract.meta.client_name.toUpperCase();

        if (contract.person_type == "Pessoa Jurídica") {
          this.contrato.cliente.cnpj = contract.meta.client_CNPJ
            ? contract.meta.client_CNPJ
            : "";
        }

        if (contract.person_type == "Pessoa Física") {
          this.contrato.cliente.nascimento = this.formatMultiDates(
            contract.meta.client_birthday
          );
          this.contrato.cliente.cpf = contract.meta.client_CPF
            ? contract.meta.client_CPF
            : "";
          this.contrato.cliente.rg = contract.meta.client_RG
            ? contract.meta.client_RG
            : "";
          this.contrato.cliente.orgao = contract.meta.client_doc_emission
            ? contract.meta.client_doc_emission.toUpperCase() +
              "-" +
              contract.meta.client_doc_emission_state
            : "";
        }

        this.contrato.cliente.telefone = contract.meta.client_phone;
        this.contrato.cliente.celular = contract.meta.client_mobile_phone;
        this.contrato.cliente.email = contract.meta.client_email.toUpperCase();
        this.contrato.cliente.endereco.rua =
          contract.meta.client_location_street.toUpperCase();
        this.contrato.cliente.endereco.numero =
          contract.meta.client_location_number;
        this.contrato.cliente.endereco.bairro =
          contract.meta.client_location_neighborhood.toUpperCase();
        this.contrato.cliente.endereco.complemento = contract.meta
          .client_location_complement
          ? contract.meta.client_location_complement.toUpperCase()
          : "";
        this.contrato.cliente.endereco.estado =
          contract.meta.client_location_state.toUpperCase();
        this.contrato.cliente.endereco.cidade =
          contract.meta.client_location_city.toUpperCase();
        this.contrato.cliente.endereco.cep = contract.meta.client_CEP;
      } else {
        this.contrato.cliente.pessoa = contract.customer.person_type;
        this.contrato.cliente.cnpj = contract.customer.cnpj;
        this.contrato.cliente.empresa = contract.customer?.company_name;
        this.contrato.cliente.nome = `${contract.customer.first_name.toUpperCase()} ${contract.customer.last_name.toUpperCase()}`;
        this.contrato.cliente.telefone = contract.customer.phone;
        this.contrato.cliente.celular = contract.customer.mobile_phone;
        this.contrato.cliente.email = contract.customer.email.toUpperCase();
        this.contrato.cliente.endereco.rua =
          contract.customer.location_street.toUpperCase();
        this.contrato.cliente.endereco.numero =
          contract.customer.location_number;
        this.contrato.cliente.endereco.bairro =
          contract.customer.location_neighborhood.toUpperCase();
        this.contrato.cliente.endereco.complemento = contract.customer
          .location_complement
          ? contract.customer.location_complement.toUpperCase()
          : "";
        this.contrato.cliente.endereco.estado =
          contract.customer.location_state.toUpperCase();
        this.contrato.cliente.endereco.cidade =
          contract.customer.location_city.toUpperCase();
        this.contrato.cliente.endereco.cep = contract.customer.cep;

        this.contrato.cliente.nascimento = this.formatMultiDates(
          contract.customer.birthday
        );

        this.contrato.cliente.cpf = contract.customer.cpf
          ? contract.customer.cpf
          : "";

        this.contrato.cliente.rg = contract.customer.rg
          ? contract.customer.rg
          : "";
        this.contrato.cliente.orgao = contract.customer.rg_emissor
          ? contract.customer.rg_emissor.toUpperCase() +
            "-" +
            contract.customer.rg_state
          : "";
      }

      console.log("ESTRUTURA CONTRATO PARTE 1");

      console.log("ESTRUTURA CONTRATO - PARTE 2");

      let findMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let replaceMonth = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      this.contrato.cliente.data = replaceOnce(
        format(new Date(), "dd, MMMM, yyyy"),
        findMonth,
        replaceMonth,
        "gi"
      );

      let productNames = "";
      let contracted_services = JSON.parse(contract.meta.contracted_services);

      contracted_services.forEach((produto) => {
        if (produto == "hotel") {
          productNames += "Hotel, ";
        }

        if (produto == "flight") {
          productNames += "Passagem Aérea, ";
        }

        if (produto == "service") {
          productNames += "Outros, ";
        }
      });

      console.log("ESTRUTURA CONTRATO - PARTE 3");

      this.contrato.produtos = productNames.slice(0, -2);

      // PAGAMENTO

      let theBank = {
        number: "",
        account: "",
        agency: "",
        cnpj: "",
        company_name: "",
        name: "",
        company: {
          company_name: "",
        },
      };

      console.log(
        "ESTRUTURA CONTRATO - PARTE 3.1",
        this.banks,
        this.companyBankAccounts.list
      );

      if (this.companyBankAccounts.list.length > 0) {
        this.companyBankAccounts.list.forEach((bank) => {
          console.log("ESTRUTURA CONTRATO - PARTE 3.1.2");
          if (contract.id < 196 && contract.id != 43) {
            if (
              parseInt(bank.number) ==
              contract.meta.payment_methods_bank_transfer_bank
            ) {
              console.log("ESTRUTURA CONTRATO - PARTE 3.1.2.1");
              theBank = bank;
            }
          } else {
            console.log(
              "ESTRUTURA CONTRATO - PARTE 3.1.1",
              bank.account,
              contract.meta.payment_methods_bank_transfer_bank
            );

            if (
              bank.account == contract.meta.payment_methods_bank_transfer_bank
            ) {
              theBank = bank;
            }
          }
        });
      }

      console.log("ESTRUTURA CONTRATO - PARTE 3.2", theBank);

      this.contrato.pagamento.total =
        contract.meta.contract_total_value != undefined
          ? this.formatPricePtBr(contract.meta.contract_total_value)
          : this.formatPricePtBr(contract.meta.reservation_total_price);

      console.log(
        "ESTRUTURA CONTRATO - PARTE 3.2.1",
        contract.meta.payment_methods.includes("bank-transfer")
      );

      if (contract.meta.payment_methods.includes("bank-transfer")) {
        this.contrato.pagamento.transferencia.ativo =
          contract.meta.payment_methods.includes("bank-transfer");

        this.contrato.pagamento.transferencia.lista = [];
        if (contract.meta.bank_transfer_rows !== undefined) {
          JSON.parse(contract.meta.bank_transfer_rows).forEach(({ id }) => {
            this.contrato.pagamento.transferencia.lista.push({
              valor: this.formatAnyPricePtBr(
                contract.meta[`payment_methods_bank_transfer_total_value_${id}`]
              ),
              banco: this.getBankDataByNumber(
                contract.meta[`payment_methods_bank_transfer_bank_${id}`]
              ),
            });
          });
        } else {
          this.contrato.pagamento.transferencia.banco = theBank;
          this.contrato.pagamento.transferencia.valor = this.formatAnyPricePtBr(
            contract.meta.payment_methods_bank_transfer_total_value
          );
        }
      }

      console.log("ESTRUTURA CONTRATO - PARTE 3.2.5");

      if (contract.meta.payment_methods.includes("custom-payment")) {
        const customPaymentRows =
          contract.meta.custom_payment_rows !== undefined
            ? JSON.parse(contract.meta["custom_payment_rows"])
            : [];

        let customPaymentName = "";

        if (customPaymentRows.length) {
          customPaymentRows.forEach(({ id }) => {
            customPaymentName += `${
              contract.meta[`payment_methods_custom_payment_name_${id}`]
            }, `;
          });

          this.contrato.pagamento.personalizado.nome = customPaymentName
            .slice(0, -2)
            .toUpperCase();
        } else {
          this.contrato.pagamento.personalizado.nome =
            contract.meta.payment_methods_custom_payment_name;
        }

        this.contrato.pagamento.personalizado.ativo =
          contract.meta.payment_methods.includes("custom-payment");
        this.contrato.pagamento.personalizado.obs =
          contract.meta.payment_methods_custom_payment_observation;
        this.contrato.pagamento.personalizado.valor = this.formatAnyPricePtBr(
          contract.meta.payment_methods_custom_payment_total_value
        );
      }

      console.log("ESTRUTURA CONTRATO - PARTE 3.3");

      if (contract.meta.payment_methods.includes("payment-link")) {
        this.contrato.pagamento.link.ativo = true;

        this.contrato.pagamento.link.valor = this.formatAnyPricePtBr(
          contract.meta.payment_methods_payment_link_total_value
        );

        if (contract.meta.payment_link_rows != undefined) {
          JSON.parse(contract.meta.payment_link_rows).forEach((pCard) => {
            console.log("LINK PG");
            this.contrato.pagamento.link.lista.push({
              id: pCard.id,
              valor: this.formatAnyPricePtBr(
                contract.meta[
                  `payment_methods_payment_link_total_value_${pCard.id}`
                ]
              ),
            });
          });
        }
      }

      if (contract.meta.payment_methods.includes("billing-ticket")) {
        console.log("ESTRUTURA CONTRATO - PARTE 3.3.1");

        this.contrato.pagamento.boleto.ativo =
          contract.meta.payment_methods.includes("billing-ticket");

        this.contrato.pagamento.boleto.valor = this.formatAnyPricePtBr(
          contract.meta.payment_methods_billing_ticket_total_value
        );

        this.contrato.pagamento.boleto.obs =
          contract.meta.payment_methods_billing_ticket_observation;
        this.contrato.pagamento.boleto.parcelas =
          contract.meta.payment_methods_billing_ticket_installments;
      }

      console.log("ESTRUTURA CONTRATO - PARTE 3.4");

      if (contract.meta.payment_methods.includes("payment-card")) {
        this.contrato.pagamento.carta.ativo =
          contract.meta.payment_methods.includes("payment-card");
        this.contrato.pagamento.carta.valor = this.formatAnyPricePtBr(
          contract.meta.payment_methods_payment_card_total_value
        );
        this.contrato.pagamento.carta.id_voyage = contract.meta
          .payment_methods_payment_card_voyage_id
          ? contract.meta.payment_methods_payment_card_voyage_id
          : "";

        if (contract.meta.payment_card_rows != undefined) {
          JSON.parse(contract.meta.payment_card_rows).forEach((pCard) => {
            console.log("CARTA CREDITO");
            this.contrato.pagamento.carta.lista.push({
              id: pCard.id,
              valor: this.formatAnyPricePtBr(
                contract.meta[
                  `payment_methods_payment_card_total_value_${pCard.id}`
                ]
              ),
              id_voyage: contract.meta[
                `payment_methods_payment_card_voyage_id_${pCard.id}`
              ]
                ? contract.meta[
                    `payment_methods_payment_card_voyage_id_${pCard.id}`
                  ]
                : "",
            });
          });
        }
      }

      console.log("ESTRUTURA CONTRATO - PARTE 3.5");

      this.contrato.pagamento.terceiros = {};

      this.contrato.pagamento.terceiros.ativo =
        contract.meta.payment_methods.includes("third-party");

      console.log("ESTRUTURA CONTRATO - PARTE 4");

      this.contrato.pagamento.terceiros.lista = [];

      console.log("Terceiros");

      if (
        contract.meta.payment_methods.includes("third-party") &&
        contract.meta["third_party_payment_rows"] != undefined
      ) {
        console.log("Terceiros setup", contract.meta.third_party_payment_rows);
        JSON.parse(contract.meta.third_party_payment_rows).forEach((person) => {
          this.contrato.pagamento.terceiros.lista.push({
            id: person.id,
            check: true,
            nome:
              contract.meta[
                "third_party_client_first_name_" + person.id
              ].toUpperCase() +
              " " +
              contract.meta[
                "third_party_client_last_name_" + person.id
              ].toUpperCase(),
            nascimento: this.formatMultiDates(
              contract.meta["third_party_client_birthday_" + person.id]
            ),
            cpf: contract.meta["third_party_client_CPF_" + person.id],
            orgao:
              contract.meta[
                "third_party_client_doc_emission_" + person.id
              ].toUpperCase() +
              "-" +
              contract.meta[
                "third_party_client_doc_emission_state_" + person.id
              ],
            rg: contract.meta["third_party_client_RG_" + person.id],
            telefone: contract.meta["third_party_client_phone_" + person.id],
            celular:
              contract.meta["third_party_client_mobile_phone_" + person.id],
            email:
              contract.meta[
                "third_party_client_email_" + person.id
              ].toUpperCase(),
            rua: contract.meta[
              "third_party_client_location_street_" + person.id
            ].toUpperCase(),
            numero:
              contract.meta["third_party_client_location_number_" + person.id],
            bairro: contract.meta[
              "third_party_client_location_neighborhood_" + person.id
            ]
              ? contract.meta[
                  "third_party_client_location_neighborhood_" + person.id
                ].toUpperCase()
              : "",
            complemento:
              contract.meta[
                "third_party_client_location_complement_" + person.id
              ] != undefined
                ? contract.meta[
                    "third_party_client_location_complement_" + person.id
                  ].toUpperCase()
                : "",
            estado:
              contract.meta["third_party_client_location_state_" + person.id],
            cidade:
              contract.meta[
                "third_party_client_location_city_" + person.id
              ].toUpperCase(),
            cep: contract.meta["third_party_client_CEP_" + person.id],
            valor:
              "R$ " +
              this.formatAnyPricePtBr(
                contract.meta["third_party_client_paid_value_" + person.id]
              ),
          });
        });
      }

      console.log("CARTAO CREDITO");

      console.log("ESTRUTURA CONTRATO - PARTE 5");

      this.contrato.pagamento.credito.ativo =
        contract.meta.payment_methods.includes("credit-card");

      console.log("ESTRUTURA CONTRATO - PARTE 5.2");

      if (contract.meta["payment_methods"]) {
        if (JSON.parse(contract.meta.payment_methods).includes("credit-card")) {
          (this.contrato.pagamento.credito.valor =
            contract.meta.payment_methods_credit_card_total_value),
            (this.contrato.pagamento.credito.cartao = []);

          if (JSON.parse(contract.meta.credit_card_rows) != undefined) {
            JSON.parse(contract.meta.credit_card_rows).forEach((card) => {
              console.log("CARTAO CREDITO2");
              this.contrato.pagamento.credito.cartao.push({
                id: card.id,
                check: true,
                pagamento_online:
                  contract.meta[
                    `payment_methods_credit_card_is_payment_online_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_is_payment_online_${card.id}`
                      ]
                    : 0,
                numeros: {
                  primeiro: "xxxx",
                  segundo: "xxxx",
                  terceiro: "xxxx",
                  quarto: "xxxx",
                },
                expiracao: `${
                  contract.meta[
                    `payment_methods_credit_card_masked_month_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_masked_month_${card.id}`
                      ]
                    : ""
                }/${
                  contract.meta[
                    `payment_methods_credit_card_masked_year_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_masked_year_${card.id}`
                      ]
                    : ""
                }`,
                cvv: `${
                  contract.meta[
                    `payment_methods_credit_card_masked_cvv_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_masked_cvv_${card.id}`
                      ]
                    : ""
                }`,
                nome: `${
                  contract.meta[
                    `payment_methods_credit_card_masked_name_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_masked_name_${card.id}`
                      ].toUpperCase()
                    : ""
                }`,
                bandeira: `${
                  contract.meta[
                    `payment_methods_credit_card_masked_flag_${card.id}`
                  ] != undefined
                    ? contract.meta[
                        `payment_methods_credit_card_masked_flag_${card.id}`
                      ].trim()
                    : ""
                }`,
                valor: `${
                  contract.meta[
                    `payment_methods_credit_card_total_value_${card.id}`
                  ] != undefined
                    ? this.formatAnyPricePtBr(
                        contract.meta[
                          `payment_methods_credit_card_total_value_${card.id}`
                        ]
                      )
                    : ""
                }`,
                parcelas: `${
                  contract.meta[
                    `payment_methods_credit_card_installments_${card.id}`
                  ]
                }`,
                primeira_parcela: `R$ ${
                  contract.meta[
                    `payment_methods_credit_card_first_installment_value_${card.id}`
                  ]
                }`, //
                demais_parcelas: `R$  ${
                  contract.meta[
                    `payment_methods_credit_card_installments_value_${card.id}`
                  ]
                }`,
                taxas: `${
                  contract.meta[
                    `payment_methods_credit_card_tax_value_${card.id}`
                  ] != undefined
                    ? this.formatAnyPricePtBr(
                        contract.meta[
                          `payment_methods_credit_card_tax_value_${card.id}`
                        ]
                      )
                    : "<font style='color: transparent'>___</font>"
                }`,
                proprietario: {
                  tipo:
                    contract.meta[
                      `payment_methods_credit_card_owner_${card.id}`
                    ] != undefined
                      ? contract.meta[
                          `payment_methods_credit_card_owner_${card.id}`
                        ]
                      : "client",
                  nome:
                    contract.meta[
                      `payment_methods_credit_card_owner_${card.id}_name`
                    ] != undefined
                      ? contract.meta[
                          `payment_methods_credit_card_owner_${card.id}_name`
                        ].toUpperCase()
                      : "",
                  cpf:
                    contract.meta[
                      `payment_methods_credit_card_owner_${card.id}_CPF`
                    ] != undefined
                      ? contract.meta[
                          `payment_methods_credit_card_owner_${card.id}_CPF`
                        ]
                      : "",
                  telefone:
                    contract.meta[
                      `payment_methods_credit_card_owner_${card.id}_phone`
                    ] != undefined
                      ? contract.meta[
                          `payment_methods_credit_card_owner_${card.id}_phone`
                        ]
                      : "",
                  celular:
                    contract.meta[
                      `payment_methods_credit_card_owner_${card.id}_mobile_phone`
                    ] != undefined
                      ? contract.meta[
                          `payment_methods_credit_card_owner_${card.id}_mobile_phone`
                        ]
                      : "",
                  endereco: {
                    rua:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_street`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_street`
                          ].toUpperCase()
                        : "",
                    numero:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_number`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_number`
                          ].toUpperCase()
                        : "",
                    bairro:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_neighborhood`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_neighborhood`
                          ].toUpperCase()
                        : "",
                    complement:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_complement`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_complement`
                          ].toUpperCase()
                        : "",
                    estado:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_state`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_state`
                          ].toUpperCase()
                        : "",
                    cidade:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_location_city`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_location_city`
                          ].toUpperCase()
                        : "",
                    cep:
                      contract.meta[
                        `payment_methods_credit_card_owner_${card.id}_CEP`
                      ] != undefined
                        ? contract.meta[
                            `payment_methods_credit_card_owner_${card.id}_CEP`
                          ].toUpperCase()
                        : "",
                  },
                },
                viajantes: contract.meta[
                  `payment_methods_credit_card_travellers_${card.id}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_travellers_${card.id}`
                    ]
                  : [],
              });
            });
          }
        }
      }

      console.log("ESTRUTURA CONTRATO - PARTE 6");

      // VIAJANTES

      this.contrato.listaViajantes = {
        todos: [],
        hotel: [],
        voo: [],
        servico: [],
      };

      this.contrato.viajantes = [];

      let hotels =
        contract.meta["hotel_rows"] != undefined
          ? contract.meta.hotel_rows
          : [];
      hotels;
      if (contract.meta.travellers_rows != undefined) {
        JSON.parse(contract.meta.travellers_rows).forEach((viajante) => {
          this.contrato.listaViajantes.todos.push({
            id: viajante.id,
            nome: `${contract.meta[
              `traveller_${viajante.id}_first_name`
            ].toUpperCase()} ${contract.meta[
              `traveller_${viajante.id}_last_name`
            ].toUpperCase()}`,
            nascimento:
              contract.meta[`traveller_${viajante.id}_birthday`] != undefined
                ? this.formatMultiDates(
                    contract.meta[`traveller_${viajante.id}_birthday`]
                  )
                : "",
            cpf: `${
              contract.meta[`traveller_${viajante.id}_CPF`]
                ? contract.meta[`traveller_${viajante.id}_CPF`]
                : ""
            }`,
          });
        });
      }

      console.log("ESTRUTURA CONTRATO - PARTE 6.1");

      if (contracted_services.includes("flight")) {
        if (contract.meta["flight_sections_rows"] != undefined) {
          console.log("ESTRUTURA CONTRATO - PARTE 6.2");

          let flight_sections_rows = JSON.parse(
            contract.meta.flight_sections_rows
          );

          console.log("flight_sections_rows", flight_sections_rows);

          console.log("ESTRUTURA CONTRATO - PARTE 6.3");

          flight_sections_rows.forEach((flightSection, index) => {
            console.log("ESTRUTURA CONTRATO - PARTE 6.3.1", flightSection);

            this.contrato.aereo.tipo = "Novo Formato";
            this.contrato.aereo.voos.push({
              id: flightSection.id,
              valor: contract.meta[`flight_${flightSection.id}_total_price`]
                ? "R$ " +
                  this.formatAnyPricePtBr(
                    contract.meta[`flight_${flightSection.id}_total_price`]
                  )
                : "",
              valor_extenso:
                contract.meta[
                  `flight_${flightSection.id}_total_price_written`
                ] != undefined
                  ? contract.meta[
                      `flight_${flightSection.id}_total_price_written`
                    ].toUpperCase()
                  : "",
              viajantes: [],
              trechos: [],
              cias: [],
            });

            console.log("ESTRUTURA CONTRATO - PARTE 6.3.2");

            flightSection.airlines.forEach((airline) => {
              if (
                parseInt(this.contrato.id) > 492 ||
                parseInt(this.contrato.id) == 380 ||
                parseInt(this.contrato.id) == 460
              ) {
                this.contrato.aereo.voos[index].cias.push({
                  razao_social: contract.meta[
                    `flight_${flightSection.id}_airline_${airline.id}`
                  ]
                    ? contract.meta[
                        `flight_${flightSection.id}_airline_${airline.id}`
                      ].split("|")[3]
                    : this.getCiaDataByName(
                        contract.meta[
                          `flight_${flightSection.id}_section_1_airline`
                        ]
                      )?.company_name,
                  nome_fantasia: contract.meta[
                    `flight_${flightSection.id}_airline_${airline.id}`
                  ]
                    ? contract.meta[
                        `flight_${flightSection.id}_airline_${airline.id}`
                      ].split("|")[2]
                    : this.getCiaDataByName(
                        contract.meta[
                          `flight_${flightSection.id}_section_1_airline`
                        ]
                      )?.trading_name,
                  cnpj: contract.meta[
                    `flight_${flightSection.id}_airline_${airline.id}`
                  ]
                    ? contract.meta[
                        `flight_${flightSection.id}_airline_${airline.id}`
                      ].split("|")[4]
                    : this.getCiaDataByName(
                        contract.meta[
                          `flight_${flightSection.id}_section_1_airline`
                        ]
                      )?.cnpj,
                });
              } else {
                this.contrato.aereo.voos[index].cias.push({
                  razao_social:
                    contract.meta[
                      `flight_${flightSection.id}_airline_company_name_${airline.id}`
                    ].toUpperCase(),
                  nome_fantasia:
                    contract.meta[
                      `flight_${flightSection.id}_airline_trading_name_${airline.id}`
                    ].toUpperCase(),
                  cnpj: contract.meta[
                    `flight_${flightSection.id}_airline_CNPJ_${airline.id}`
                  ],
                });
              }
            });

            console.log("ESTRUTURA CONTRATO - PARTE 6.3.3");

            flightSection.sections.forEach((section) => {
              let departureDate =
                contract.meta[
                  `flight_${flightSection.id}_section_${section.id}_departure_date`
                ];

              let arrivalDate =
                contract.meta[
                  `flight_${flightSection.id}_section_${section.id}_arrival_date`
                ];

              this.contrato.aereo.voos[index].trechos.push({
                id: section.id,
                cia: contract.meta[
                  `flight_${flightSection.id}_section_${section.id}_airline`
                ].toUpperCase(),
                // cia: contract.meta[
                //     `flight_${flightSection.id}_section_${section.id}_airline`
                // ],
                numero:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_number`
                  ],
                paradas:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_stops`
                  ],
                data_saida: this.formatMultiDates(departureDate.split("T")[0]),
                horario_saida:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_departure_time`
                  ],
                data_chegada: this.formatMultiDates(arrivalDate.split("T")[0]),
                horario_chegada:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_arrival_time`
                  ],
                duracao:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_duration`
                  ],
                origem:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_origin`
                  ].toUpperCase(),
                destino:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_destination`
                  ].toUpperCase(),
                classe:
                  contract.meta[
                    `flight_${flightSection.id}_section_${section.id}_class`
                  ].toUpperCase(),
              });
            });

            console.log("ESTRUTURA CONTRATO - PARTE 6.3.4");

            flightSection.travellers.forEach((traveller) => {
              if (this.contract.id < 373 && this.contract.id != 43) {
                this.contrato.aereo.voos[index].viajantes.push({
                  id: traveller.id,
                  nome:
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_first_name_" +
                        traveller.id
                    ].toUpperCase() +
                    " " +
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_last_name_" +
                        traveller.id
                    ].toUpperCase(),
                  nascimento: this.formatMultiDates(
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_birthday_" +
                        traveller.id
                    ]
                  ),
                  cpf: contract.meta[
                    "flight_" + flightSection.id + "_person_CPF_" + traveller.id
                  ],
                  valor:
                    "R$ " +
                    this.formatAnyPricePtBr(
                      contract.meta[
                        "flight_" +
                          flightSection.id +
                          "_person_price_" +
                          traveller.id
                      ]
                    ),
                });

                this.contrato.viajantes.push({
                  id: traveller.id,
                  nome:
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_first_name_" +
                        traveller.id
                    ]
                      .toUpperCase()
                      .trim() +
                    " " +
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_last_name_" +
                        traveller.id
                    ]
                      .toUpperCase()
                      .trim(),
                  nascimento: this.formatMultiDates(
                    contract.meta[
                      "flight_" +
                        flightSection.id +
                        "_person_birthday_" +
                        traveller.id
                    ]
                  ),
                  cpf: contract.meta[
                    "flight_" + flightSection.id + "_person_CPF_" + traveller.id
                  ],
                  valor: contract.meta[
                    "flight_" +
                      flightSection.id +
                      "_person_price_" +
                      traveller.id
                  ]
                    ? "R$ " +
                      this.formatAnyPricePtBr(
                        contract.meta[
                          "flight_" +
                            flightSection.id +
                            "_person_price_" +
                            traveller.id
                        ]
                      )
                    : "",
                });
              } else {
                let currency = "R$";

                if (
                  contract.meta[
                    `flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`
                  ] != undefined
                ) {
                  currency = this.formatCurrency(
                    contract.meta[
                      `flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`
                    ]
                  );
                }

                console.log(
                  "ESTRUTURA CONTRATO - PARTE 6.3.5",
                  contract.meta[
                    `flight_${flightSection.id}_traveller_${traveller.id}_value`
                  ]
                );

                this.contrato.listaViajantes.todos.forEach((viajante) => {
                  if (
                    viajante.id ==
                    contract.meta[
                      `flight_${flightSection.id}_traveller_${traveller.id}`
                    ]
                  ) {
                    //
                    this.contrato.listaViajantes.voo.push(viajante);
                    this.contrato.aereo.voos[index].viajantes.push({
                      ...viajante,
                      valor:
                        contract.meta[
                          `flight_${flightSection.id}_traveller_${traveller.id}_value`
                        ] != undefined
                          ? this.formatAnyPricePtBr(
                              contract.meta[
                                `flight_${flightSection.id}_traveller_${traveller.id}_value`
                              ]
                            )
                          : "",
                      taxas: `${currency} ${
                        contract.meta[
                          `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
                            ]
                          : "0,00"
                      }`,
                    });
                  }
                });
              }
            });
          });
        } else {
          console.log("ESTRUTURA CONTRATO - PARTE 6.2");

          let flight_travellers =
            contract.meta["flight_travellers"] != undefined
              ? contract.meta.flight_travellers
              : [];

          let flight_rows =
            contract.meta["flight_rows"] != undefined
              ? JSON.parse(contract.meta.flight_rows)
              : [];

          if (contracted_services.includes("flight")) {
            this.contrato.aereo.voos = [];
            flight_rows.forEach((flight) => {
              this.contrato.aereo.voos.push({
                id: flight.id,
                cia: contract.meta[`flight_${flight.id}_airline`].toUpperCase(),
                numero: contract.meta[`flight_${flight.id}_number`],
                paradas: contract.meta[`flight_${flight.id}_stops`],
                data: this.formatMultiDates(
                  contract.meta[`flight_${flight.id}_departure_date`]
                ),
                horario: contract.meta[`flight_${flight.id}_departure_time`],
                origem:
                  contract.meta[`flight_${flight.id}_origin`].toUpperCase(),
                destino:
                  contract.meta[
                    `flight_${flight.id}_destination`
                  ].toUpperCase(),
                classe:
                  contract.meta[`flight_${flight.id}_class`].toUpperCase(),
              });
            });
          }

          console.log("ESTRUTURA CONTRATO - PARTE 7.2");

          let theAirlines = [];

          if (contracted_services.includes("flight")) {
            if (contract.meta[`flight_airlines`] != undefined) {
              contract.meta[`flight_airlines`].forEach((airline) => {
                theAirlines.push({
                  razao_social:
                    contract.meta[
                      `flight_airline_company_name_${airline.id}`
                    ].toUpperCase(),
                  nome_fantasia:
                    contract.meta[
                      `flight_airline_trading_name_${airline.id}`
                    ].toUpperCase(),
                  cnpj: contract.meta[`flight_airline_CNPJ_${airline.id}`],
                });
              });
            }
          }

          this.contrato.aereo = {
            ...this.contrato.aereo,
            valor: contract.meta[`flight_total_price`]
              ? "R$ " +
                this.formatAnyPricePtBr(contract.meta[`flight_total_price`])
              : "",
            valor_extenso:
              contract.meta[`flight_total_price_written`] != undefined
                ? contract.meta[`flight_total_price_written`].toUpperCase()
                : "",
            cias: theAirlines,
          };

          if (contracted_services.includes("flight")) {
            flight_travellers.forEach((traveller) => {
              this.contrato.viajantes.push({
                id: traveller.id,
                nome:
                  contract.meta["flight_person_first_name_" + traveller.id]
                    .toUpperCase()
                    .trim() +
                  " " +
                  contract.meta["flight_person_last_name_" + traveller.id]
                    .toUpperCase()
                    .trim(),
                nascimento: this.formatMultiDates(
                  contract.meta["flight_person_birthday_" + traveller.id]
                ),
                cpf: contract.meta["flight_person_CPF_" + traveller.id],
                valor:
                  "R$ " +
                  this.formatAnyPricePtBr(
                    contract.meta["flight_person_price_" + traveller.id]
                  ),
              });
            });
          }

          console.log("Test 2", flight_rows);
        }
      }

      let service_travellers =
        contract.meta["service_travellers"] != undefined
          ? JSON.parse(contract.meta.service_travellers)
          : [];

      let service_rows =
        contract.meta["service_rows"] != undefined
          ? JSON.parse(contract.meta.service_rows)
          : [];

      console.log("ESTRUTURA CONTRATO - PARTE 7.1", service_rows);

      if (contracted_services.includes("service")) {
        service_rows.forEach((service) => {
          let serviceTravellers = [];

          console.log("ESTRUTURA CONTRATO - PARTE 7.1.2", service.travellers);
          if (service.travellers != undefined) {
            console.log("ESTRUTURA CONTRATO - PARTE 7.1.2", service.travellers);
            service.travellers.forEach((traveller) => {
              console.log(
                "ESTRUTURA CONTRATO - PARTE 7.1.2",
                traveller,
                contract.meta[
                  `service_${service.id}_traveller_${traveller.id}`
                ],
                this.contrato.listaViajantes.todos
              );
              this.contrato.listaViajantes.todos.forEach((viajante) => {
                console.log("ESTRUTURA CONTRATO - PARTE 7.1.2.1", viajante);
                if (
                  viajante.id ==
                  contract.meta[
                    `service_${service.id}_traveller_${traveller.id}`
                  ]
                ) {
                  serviceTravellers.push(viajante);
                }
              });
            });
          }

          this.contrato.listaServicos.push({
            id: service.id,
            nome: `${
              contract.meta[`service_${service.id}_type`] != undefined
                ? contract.meta[`service_${service.id}_type`] + " - "
                : ""
            } ${contract.meta[`service_${service.id}_name`].toUpperCase()} `,
            checkin: `${
              contract.meta[`service_${service.id}_checkin`] != undefined
                ? this.formatMultiDates(
                    contract.meta[`service_${service.id}_checkin`]
                  )
                : ""
            }`,
            checkout: `${
              contract.meta[`service_${service.id}_checkout`] != undefined
                ? this.formatMultiDates(
                    contract.meta[`service_${service.id}_checkout`]
                  )
                : ""
            }`,
            valor:
              contract.meta[`service_${service.id}_total_price`] != undefined
                ? "R$ " +
                  this.formatPrice(
                    contract.meta[`service_${service.id}_total_price`]
                  )
                : 0,
            viajantes: serviceTravellers,
          });
        });
      }

      console.log(
        "ESTRUTURA CONTRATO - PARTE 7.4",
        hotels,
        contracted_services
      );

      if (contracted_services.includes("hotel")) {
        JSON.parse(hotels).forEach((hotel) => {
          let accommodations = [];

          hotel.accommodation.forEach((room) => {
            let allTravellers = [];

            room.travellers.forEach((traveller) => {
              this.contrato.listaViajantes.todos.forEach((viajante) => {
                if (
                  viajante.id ==
                  contract.meta[
                    `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                  ]
                ) {
                  allTravellers.push(viajante);
                }
              });
            });
            accommodations.push({
              id: room.id,
              nome: contract.meta[
                "hotel_" + hotel.id + "_accommodation_" + room.id
              ].toUpperCase(),
              tipo: contract.meta[
                "hotel_" + hotel.id + "_accommodation_" + room.id + "_type"
              ],
              hospedes: allTravellers,
            });

            console.log("ESTRUTURA CONTRATO - PARTE 7.6", room.travellers);

            room.travellers.forEach((traveller) => {
              console.log(traveller);

              console.log("ESTRUTURA CONTRATO - PARTE 7.8", traveller);

              this.contrato.listaViajantes.todos.forEach((viajante) => {
                if (
                  viajante.id ==
                  contract.meta[
                    `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                  ]
                ) {
                  this.contrato.listaViajantes.hotel.push(viajante);
                }
              });

              this.contrato.viajantes.push({
                id: traveller.id,
                nome:
                  contract.meta[
                    "hotel_" +
                      hotel.id +
                      "_accommodation_" +
                      room.id +
                      "_person_first_name_" +
                      traveller.id
                  ] != undefined
                    ? contract.meta[
                        "hotel_" +
                          hotel.id +
                          "_accommodation_" +
                          room.id +
                          "_person_first_name_" +
                          traveller.id
                      ]
                        .toUpperCase()
                        .trim() +
                      " " +
                      contract.meta[
                        "hotel_" +
                          hotel.id +
                          "_accommodation_" +
                          room.id +
                          "_person_last_name_" +
                          traveller.id
                      ]
                        .toUpperCase()
                        .trim()
                    : "",
                nascimento:
                  contract.meta[
                    "hotel_" +
                      hotel.id +
                      "_accommodation_" +
                      room.id +
                      "_person_birthday_" +
                      traveller.id
                  ] != undefined
                    ? this.formatMultiDates(
                        contract.meta[
                          "hotel_" +
                            hotel.id +
                            "_accommodation_" +
                            room.id +
                            "_person_birthday_" +
                            traveller.id
                        ]
                      )
                    : "",
                cpf: contract.meta[
                  "hotel_" +
                    hotel.id +
                    "_accommodation_" +
                    room.id +
                    "_person_CPF_" +
                    traveller.id
                ],
              });
            });
          });

          console.log("ESTRUTURA CONTRATO - PARTE 7.9");

          this.contrato.listaHoteis.push({
            id: hotel.id,
            nome: contract.meta["hotel_" + hotel.id + "_name"].toUpperCase(),
            regime: contract.meta["hotel_" + hotel.id + "_diet"].toUpperCase(),
            checkin: this.formatMultiDates(
              contract.meta["hotel_" + hotel.id + "_checkin"]
            ),
            checkout: this.formatMultiDates(
              contract.meta["hotel_" + hotel.id + "_checkout"]
            ),
            noites: contract.meta["hotel_" + hotel.id + "_rates"],
            valor:
              "R$ " +
              this.formatAnyPricePtBr(
                contract.meta["hotel_" + hotel.id + "_total_price"]
              ),
            acomodacoes: accommodations,
          });
        });
      }

      console.log("ESTRUTURA CONTRATO - PARTE 8");

      if (contracted_services.includes("service")) {
        service_travellers.forEach((traveller) => {
          this.contrato.listaViajantes.todos.forEach((viajante) => {
            if (
              viajante.id == contract.meta[`service_traveller_${traveller.id}`]
            ) {
              this.contrato.listaViajantes.servico.push(viajante);
            }
          });

          this.contrato.viajantes.push({
            id: traveller.id,
            nome:
              contract.meta["service_person_last_name_" + traveller.id] !=
              undefined
                ? contract.meta["service_person_first_name_" + traveller.id]
                    .toUpperCase()
                    .trim() +
                  " " +
                  contract.meta["service_person_last_name_" + traveller.id]
                    .toUpperCase()
                    .trim()
                : "",
            nascimento:
              contract.meta["service_person_birthday_" + traveller.id] !=
              undefined
                ? this.formatMultiDates(
                    contract.meta["service_person_birthday_" + traveller.id]
                  )
                : "",
            cpf: contract.meta["service_person_CPF_" + traveller.id],
          });
        });
      }

      if (parseInt(this.contrato.id) > 373) {
        this.contrato.viajantes = this.contrato.listaViajantes.todos;
      }

      console.log("via", this.contrato.viajantes);

      console.log("ESTRUTURA CONTRATO - PARTE 9");

      function removeDuplicates(arr, equals) {
        var originalArr = arr.slice(0);
        var i, len, val;
        arr.length = 0;

        for (i = 0, len = originalArr.length; i < len; ++i) {
          val = originalArr[i];
          if (
            !arr.some(function (item) {
              return equals(item, val);
            })
          ) {
            arr.push(val);
          }
        }
      }

      function thingsEqual(thing1, thing2) {
        return (
          thing1.nome == thing2.nome && thing1.nascimento == thing2.nascimento
        );
      }

      removeDuplicates(this.contrato.viajantes, thingsEqual);

      // NOVA VERSAO DAS POLITICAS CONTRATOS ACIMA DO ID 2262
      if (parseInt(contract.id) > 2262) {
        console.log(
          "ESTRUTURA CONTRATO - PARTE 10",
          parseInt(contract.id),
          this.policy.list
        );

        this.contrato.politicas = this.policy.list;
        //reservation_policies
        //
      } else {
        let find = ["<p><br></p>"];
        let replace = [""];
        let policiesDB = contract.meta.reservation_policies;

        console.log(
          "ESTRUTURA CONTRATO - PARTE 10",
          parseInt(contract.id),
          policiesDB
        );

        if (parseInt(contract.id) < 371 && parseInt(contract.id) != 43) {
          console.log("ESTRUTURA CONTRATO - PARTE 10.1");

          if (policiesDB) {
            policiesDB = atob(policiesDB);
            JSON.parse(policiesDB).forEach((policy) => {
              if (policy.showInContract) {
                this.contrato.politicas.push({
                  titulo: policy.title.toUpperCase(),
                  texto: replaceOnce(policy.text, find, replace, "gi"),
                });
              }
            });
          }
        } else {
          // console.log("ESTRUTURA CONTRATO - PARTE 10.2", JSON.parse(policiesDB));

          if (policiesDB) {
            console.log("ESTRUTURA CONTRATO - PARTE 10.5", policiesDB);
            JSON.parse(policiesDB).forEach((policy) => {
              console.log("ESTRUTURA CONTRATO - PARTE 10.6", policy);
              if (policy.showInContract) {
                console.log("ESTRUTURA CONTRATO - PARTE 10.7");
                this.contrato.politicas.push({
                  titulo: policy.title.toUpperCase(),
                  texto: replaceOnce(policy.text, find, replace, "gi"),
                });
              }
            });
          }
        }
      }

      console.log("ESTRUTURA CONTRATO - PARTE 11");

      this.contrato.data = format(new Date(), "dd/MM/yyyy HH:mm");

      setTimeout(() => {
        this.docSizes.contractDetailsSize =
          document.getElementById("contract-details").clientHeight;
        console.log(
          "contractDetails",
          document.getElementById("contract-details").clientHeight
        );
      }, 100);

      console.log("ESTRUTURA CONTRATO - PARTE 12");

      console.log("CONTRATO FINALIZADO");
    },
  },
};
