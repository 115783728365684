export default {
  data() {
    return {
      document: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        tempContract: {},
        details: {},
        structure: [],
        list: [],
        filters: {
          loadingResults: false,
          showHtml: "",
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "1",
              },

              {
                label: "Desativado",
                value: "0",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          supplier: "",
          id: "",
          searchTerm: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
        meta: {},
      },
      tempDocument: {},
      loadingNewDocument: false,
      createDocument: false,
      documentssTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 66,
          sorter: true,
        },
        {
          title: "#",
          key: "contract",
          width: 70,
          scopedSlots: {
            customRender: "contract",
          },
        },
        {
          title: "",
          width: 40,
          scopedSlots: {
            customRender: "miles",
          },
        },
        {
          title: "Produto(s)",
          width: 160,
          scopedSlots: {
            customRender: "products",
          },
        },

        {
          title: "Filial",
          key: "company_branch",
          width: 120,
          scopedSlots: {
            customRender: "company_branch",
          },
        },
        {
          title: "Cliente",
          key: "customer",
          width: 170,
          scopedSlots: {
            customRender: "customer",
          },
        },
        {
          title: "Venda",
          key: "value",
          dataIndex: "value",
          width: 110,
          sorter: true,
          scopedSlots: {
            customRender: "value",
          },
        },
        {
          title: "A pagar",
          key: "value_to_pay",
          dataIndex: "value_to_pay",
          class: "red",
          sorter: true,
          width: 110,
          scopedSlots: {
            customRender: "value_to_pay",
          },
        },
        {
          title: "Taxas",
          key: "taxes_value",
          dataIndex: "taxes_value",
          width: 110,
          sorter: true,
          class: "corange",
          scopedSlots: {
            customRender: "taxes_value",
          },
        },
        {
          title: "Lucratividade",
          key: "profit_value",
          class: "cgreen",
          sorter: true,
          dataIndex: "profit_value",
          width: 140,
          scopedSlots: {
            customRender: "profit_value",
          },
        },
        {
          title: "Pagamentos",
          scopedSlots: {
            customRender: "payments",
          },
          width: 180,
        },
        {
          title: "Data da Venda",
          dataIndex: "documents_date",
          key: "documents_date",
          width: 140,
          sorter: true,
        },
        {
          title: "",
          key: "user",
          width: 60,
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",

          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    changeDocumentsPage(current) {
      this.document.pagination.page = current;
      this.getDocuments(current);
    },
    changeDocumentsPageSize(current, pageSize) {
      this.document.pagination.page = current;
      this.document.pagination.perPage = pageSize;
      this.getDocuments();
    },
    documentsTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.document.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.document.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getDocuments();
    },
    onDocumentSelected(id) {
      this.document.details = null;
      this.createDocument = false;
      this.getDocument(id);
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.document.filters.status.selected = tab;
      this.document.pagination.page = 1;
      this.getDocuments();
    },
    async getDocumentById(id) {
      try {
        const data = await this.$http.get(`/document/details?id=${id}`);
        return data;
      } catch (error) {
        console.log(error);
        // this.$message.error("Não foi possível carregar o documento.");
        this.documentation.details = {};
      }
    },
    getDocument(id) {
      this.$http
        .post("/document/details?id=" + id)
        .then(({ data }) => {
          this.loadingNewDocument = false;
          this.document.details = data;

          this.docForm.setFieldsValue({
            name: data.name,
            contract_id: data.contract_id != 0 ? data.contract_id : undefined,
          });

          this.tempDocument = {
            ...data.raw,
          };

          this.tempDocument.status =
            this.tempDocument.status == 1 ? true : false;
          this.tempDocument.is_general =
            this.tempDocument.is_general == 1 ? true : false;

          this.tempDocument.is_repeater =
            this.tempDocument.is_repeater == 1 ? true : false;

          delete this.tempDocument.structure;
          delete this.tempDocument.html;

          this.document.structure = data.html ? data.html : [];
        })
        .catch(({ response }) => {
          response;
        });
    },
    documentsFilters() {
      let filters = "";
      let queryParams = new URLSearchParams("");

      history.pushState(null, null, "");

      if (!this.$root.isAdmin()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.document.filters.users.selected.length > 0) {
          filters += `&user_id=${this.document.filters.users.selected}`;
        }

        if (!this.document.filters.id) {
          if (this.document.filters.status.selected.length > 0) {
            filters += `&status=${this.document.filters.status.selected}`;
          }

          if (this.document.filters.period.selected.length > 0) {
            filters += `&period=${this.document.filters.period.selected[0]}|${this.document.filters.period.selected[1]}`;
            queryParams.set(
              "period",
              `${this.document.filters.period.selected[0]}|${this.document.filters.period.selected[1]}`
            );
          }
        } else {
          filters += `&id=${this.document.filters.id}`;
        }

        if (this.document.filters.showHtml) {
          filters += `&html=${this.document.filters.showHtml}`;
        }

        if (this.document.filters.searchTerm) {
          filters += `&s=${this.document.filters.searchTerm}`;
        }
      }

      filters += `&order=${this.document.filters.order}&order-by=${this.document.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },

    async getDocumentsV2(samePage) {
      samePage;
      try {
        const { data } = await this.$http.get(
          `/document/list?page=${this.document.pagination.page}&per_page=${
            this.document.pagination.perPage
          }${this.documentsFilters()}`
        );
        this.document.list = data;
        return data;
      } catch (error) {
        console.log(error);
        this.document.list = [];
      }
    },
    getDocuments(samePage) {
      this.document.loading = true;

      this.$http
        .get(
          `/document/list?page=${this.document.pagination.page}&per_page=${
            this.document.pagination.perPage
          }${this.documentsFilters()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.document.pagination.page = 1;
          }

          this.document.loading = false;

          this.document.pagination.total = data.meta.total;
          this.document.pagination.totalPages = data.meta.total_pages;

          this.document.list = data.data;
          this.document.meta = data.meta;
        })
        .catch(({ response }) => {
          this.document.pagination.total = response.data.meta.total;
          this.document.pagination.totalPages = response.data.meta.total_pages;

          this.document.list = [];
          this.document.loading = false;
        });
    },
  },
};
