<template>
  <div class="contract-table">
    <div v-for="(creditCard, index) in creditCardRows" :key="index">
      <div
        v-if="page.subpages[index].check"
        :id="`doc-${page.id}-${creditCard.id}`"
      >
        <table :class="'a4 pg' + creditCard.id" width="100%" border="0">
          <tbody>
            <tr>
              <td class="td">
                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td valign="top" style="padding-top: 5px">
                        <img
                          :src="contract.company_branch.logo"
                          alt="logo"
                          style="height: 50px"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        style="
                          font-size: 18px;
                          padding-top: 10px !important;
                          padding-bottom: 10px !important;
                          line-height: 1;
                        "
                      >
                        <strong style="font-weight: 600"
                          >AUTORIZAÇÃO DE DÉBITO {{ creditCard.id }}</strong
                        >
                        - CARTÃO DE CRÉDITO
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-color: #eee;
                          border-width: 1px 0px 0px 0;
                          border-style: solid;
                          padding-top: 15px;
                        "
                      >
                        Autorizo e reconheço o débito em minha conta do cartão
                        de crédito abaixo:
                      </td>

                      <td
                        style="
                          text-align: right;
                          border-color: #eee;
                          border-width: 1px 0px 0px 0;
                          border-style: solid;
                          padding-top: 15px;
                        "
                      >
                        <strong style="font-weight: 600; font-size: 11px"
                          >Produtos:</strong
                        >
                        <font
                          style="
                            background: #f1f4ff;
                            border-bottom: 2px solid #d9d9d9;
                            padding: 0px 6px;
                            font-size: 11px;
                            margin-left: 6px;
                            font-weight: 600;
                          "
                        >
                          {{ contractedProducts.slice(0, -2) }}</font
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        style="font-size: 18px; height: 233px; line-height: 1"
                      >
                        <span style="position: relative; display: inline-block">
                          <font
                            style="
                              position: absolute;
                              top: 22px;
                              right: 153px;
                              font-weight: 600;
                              font-size: 14px;
                              width: 100px;
                              text-align: right;
                            "
                          >
                            {{
                              cardData(creditCard.id).bandeira.indexOf("xxx") >
                              -1
                                ? cardData(creditCard.id).bandeira
                                : ""
                            }}

                            <img
                              v-if="
                                cardData(creditCard.id).bandeira == 'Mastercard'
                              "
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/mastercard.png"
                              style="width: 40px"
                            />
                            <img
                              v-if="cardData(creditCard.id).bandeira == 'Visa'"
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/visa.png"
                              style="width: 100%"
                            />
                            <img
                              v-if="
                                cardData(creditCard.id).bandeira == 'Diners'
                              "
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/diners.png"
                              style="width: 100%"
                            />
                            <img
                              v-if="cardData(creditCard.id).bandeira == 'AMEX'"
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/american.png"
                              style="width: 100%"
                            />

                            <img
                              v-if="cardData(creditCard.id).bandeira == 'Elo'"
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/elo.png"
                              style="width: 100%"
                            />

                            <img
                              v-if="
                                cardData(creditCard.id).bandeira == 'Hipercard'
                              "
                              src="https://api.viajar.tur.br/v1/uploads/public/contract/hipercard.png"
                              style="width: 100%"
                            />
                          </font>

                          <font
                            style="
                              position: absolute;
                              top: 88px;
                              left: 17px;
                              font-weight: 600;
                              font-size: 12px;
                              width: 53px;
                            "
                            >{{
                              cardData(creditCard.id).numeros.primeiro
                            }}</font
                          >

                          <font
                            style="
                              position: absolute;
                              top: 88px;
                              left: 85px;
                              font-weight: 600;
                              font-size: 12px;
                              width: 53px;
                            "
                            >{{ cardData(creditCard.id).numeros.segundo }}</font
                          >

                          <font
                            style="
                              position: absolute;
                              top: 88px;
                              left: 153px;
                              font-weight: 600;
                              font-size: 12px;
                              width: 53px;
                            "
                            >{{
                              cardData(creditCard.id).numeros.terceiro
                            }}</font
                          >

                          <font
                            style="
                              position: absolute;
                              top: 88px;
                              left: 225px;
                              font-weight: 600;
                              font-size: 12px;
                              width: 53px;
                            "
                            >{{ cardData(creditCard.id).numeros.quarto }}</font
                          >

                          <font
                            style="
                              position: absolute;
                              top: 125px;
                              left: 23px;
                              font-weight: 600;
                              font-size: 12px;
                              width: 53px;
                            "
                            >{{ cardData(creditCard.id).expiracao }}</font
                          >

                          <font
                            style="
                              position: absolute;
                              top: 162px;
                              left: 20px;
                              font-weight: 600;
                              font-size: 12px;
                            "
                            >{{ cardData(creditCard.id).nome }}</font
                          >

                          <font
                            v-if="cardData(creditCard.id).cvv != undefined"
                            style="
                              position: absolute;
                              top: 122px;
                              right: 20px;
                              font-weight: 600;
                              font-size: 13px;
                            "
                          >
                            ***
                          </font>

                          <img
                            src="https://api.viajar.tur.br/v1/uploads/public/contract/credit-card-mockup.png"
                            alt="case"
                            width="420"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 11.8px"
                  width="100%"
                >
                  <tr>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 1px 0px 0px 0;
                        border-style: solid;
                        padding: 10px 5px 3px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Valor pago: </strong>
                      <strong style="font-weight: 600"></strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).valor }}</font
                      >
                    </td>
                    <td
                      colspan="3"
                      style="
                        border-color: #eee;
                        border-width: 1px 0px 0px 0;
                        border-style: solid;
                        padding: 0 0 0 5px;
                      "
                    >
                      <strong style="font-weight: 600">Valor das taxas:</strong>
                      <strong style="font-weight: 600"> </strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                        >R$ 0,00</font
                      >
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Nº de Parcelas:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                        >{{ cardData(creditCard.id).parcelas }}</font
                      >
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Valor 1ª parcela:</strong
                      >
                      <strong style="font-weight: 600"> </strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                        >{{ cardData(creditCard.id).primeira_parcela }}</font
                      >
                    </td>

                    <td
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Demais parcelas:</strong>
                      <strong style="font-weight: 600"> </strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).demais_parcelas }}
                      </font>
                    </td>
                  </tr>
                </table>

                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 11.8px"
                  width="100%"
                  v-if="cardData(creditCard.id).proprietario.tipo == 'client'"
                >
                  <tr v-if="contract.customer.person_type == 'Pessoa Física'">
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0px 0 0px 0;
                        border-style: solid;
                        padding: 10px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Nome completo:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.first_name }}
                        {{ contract.customer.last_name }}
                      </font>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0px 0 0px 0;
                        border-style: solid;
                        padding: 0px 5px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Endço. da Fatura:</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 3px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.location_street }},
                        {{ contract.customer.location_number }},
                        {{ contract.customer.location_neighborhood }},
                        {{ contract.customer.location_city }},
                        {{ contract.customer.cep }} -
                        {{ contract.customer.location_state }}
                      </font>
                    </td>
                  </tr>

                  <tr v-if="contract.customer.person_type == 'Pessoa Física'">
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">CPF:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.cpf }}</font
                      >
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Telefone Fixo:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.phone }}
                      </font>
                    </td>

                    <td
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Telefone Celular:</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.mobile_phone }}</font
                      >
                    </td>
                  </tr>

                  <tr v-if="contract.customer.person_type == 'Pessoa Jurídica'">
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0px 0 0px 0;
                        border-style: solid;
                        padding: 15px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Nome da empresa:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.trading_name }}
                      </font>
                    </td>
                  </tr>
                  <tr v-if="contract.customer.person_type == 'Pessoa Jurídica'">
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600">CNPJ:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                        >{{ contract.customer.cnpj }}</font
                      >
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Telefone Fixo:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ ccontract.customer.phone }}
                      </font>
                    </td>

                    <td
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Telefone Celular:</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ contract.customer.mobile_phone }}</font
                      >
                    </td>
                  </tr>
                </table>

                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 12px"
                  width="100%"
                  v-if="
                    cardData(creditCard.id).proprietario.tipo ==
                    'credit-card-owner'
                  "
                >
                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0px 0 0px 0;
                        border-style: solid;
                        padding: 15px 5px 10px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Nome completo:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).proprietario.nome }}
                      </font>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0px 0 0px 0;
                        border-style: solid;
                        padding: 0px 5px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Endereço da Fatura:</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).proprietario.endereco.rua }},
                        {{
                          cardData(creditCard.id).proprietario.endereco.numero
                        }},
                        {{
                          cardData(creditCard.id).proprietario.endereco.bairro
                        }},
                        {{
                          cardData(creditCard.id).proprietario.endereco.cidade
                        }},
                        {{ cardData(creditCard.id).proprietario.endereco.cep }}
                        -
                        {{
                          cardData(creditCard.id).proprietario.endereco.estado
                        }}
                      </font>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600">CPF:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).proprietario.cpf }}</font
                      >
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600">Telefone Fixo:</strong>
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).proprietario.telefone }}
                      </font>
                    </td>

                    <td
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px 5px 15px 5px;
                      "
                    >
                      <strong style="font-weight: 600"
                        >Telefone Celular:</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          border-bottom: 2px solid #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ cardData(creditCard.id).proprietario.celular }}</font
                      >
                    </td>
                  </tr>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-color: #eee;
                          border-width: 0 0px 0px 0;
                          border-style: solid;
                          padding-top: 15px;
                        "
                      >
                        Passageiros favorecidos
                      </td>

                      <td
                        style="
                          text-align: right;
                          border-color: #eee;
                          border-width: 0 0px 0px 0;
                          border-style: solid;
                          padding-top: 15px;
                        "
                      ></td>
                    </tr>
                  </tbody>
                </table>

                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 12px"
                >
                  <tr>
                    <td
                      colspan="3"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 0px 0;
                        border-style: solid;
                        padding: 10px 0 5px;
                      "
                    >
                      <ul
                        v-if="cardData(creditCard.id).viajantes.length > 0"
                        style="
                          list-style: none;
                          padding: 0;
                          -webkit-column-count: 2;
                          -moz-column-count: 2;
                          column-count: 2;
                          margin-bottom: 0;
                          text-transform: uppercase;
                        "
                      >
                        <li
                          style="padding-bottom: 5px; width: 100%"
                          v-for="(traveller, index) in cardData(creditCard.id)
                            .viajantes"
                          :key="index"
                        >
                          <strong style="display: inline-block; width: 2%">
                            {{ traveller }}.</strong
                          >
                          <font
                            style="
                              background: #f1f4ff;
                              display: inline-block;
                              width: 90%;
                              border-bottom: 2px solid #d9d9d9;
                              padding: 0px 6px;
                              margin-left: 6px;
                              font-weight: 600;
                            "
                          >
                            {{
                              contract.meta[`traveller_${traveller}_first_name`]
                            }}
                            {{
                              contract.meta[`traveller_${traveller}_last_name`]
                            }}</font
                          >
                        </li>
                      </ul>

                      <ul
                        v-else
                        style="
                          list-style: none;
                          padding: 0;
                          -webkit-column-count: 2;
                          -moz-column-count: 2;
                          column-count: 2;
                          margin-bottom: 0;
                          text-transform: uppercase;
                        "
                      >
                        <li
                          style="padding-bottom: 5px; width: 100%"
                          v-for="(traveller, index) in travellers"
                          :key="index"
                        >
                          <strong style="display: inline-block; width: 2%">
                            {{ traveller.id }}.</strong
                          >
                          <font
                            style="
                              background: #f1f4ff;
                              display: inline-block;
                              width: 90%;
                              border-bottom: 2px solid #d9d9d9;
                              padding: 0px 6px;
                              margin-left: 6px;
                              font-weight: 600;
                            "
                          >
                            {{
                              contract.meta[
                                `traveller_${traveller.id}_first_name`
                              ]
                            }}
                            {{
                              contract.meta[
                                `traveller_${traveller.id}_last_name`
                              ]
                            }}</font
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-color: #eee;
                          border-width: 0px 0px 0px 0;
                          border-style: solid;
                          padding-top: 5px;
                        "
                      >
                        Autorizo e reconheço os passageiros informados acima.
                      </td>

                      <td
                        style="
                          text-align: right;
                          border-color: #eee;
                          border-width: 0px 0px 0px 0;
                          border-style: solid;
                          padding-top: 0px;
                        "
                      ></td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        valign="top"
                        width="50%"
                        style="
                          text-align: justify;
                          font-size: 10px;
                          border-color: #eee;
                          color: #666;
                          border-width: 0px 0px 0px 0;
                          border-style: solid;
                          padding: 5px 20px 5px 0;
                        "
                      >
                        <p style="margin: 0 0 5px">
                          <strong
                            style="
                              font-size: 13px;
                              color: #666;
                              font-weight: 600;
                            "
                            >ATENÇÃO:
                          </strong>
                        </p>

                        <p>
                          Qualquer transação realizada fora dos padrões
                          contratuais implicará sanções legais.
                        </p>

                        <p>
                          Ao autorizar o débito no cartão de crédito, Portador e
                          Estabelecimento declaram estar cientes e concordar com
                          as seguintes condições:
                        </p>

                        <p>
                          1) Questionamentos ou cancelamentos dos serviços
                          prestados seguem diretrizes específicas do fornecedor
                          do produto em pauta.
                        </p>

                        <p>
                          2) O estabelecimento é responsável pela correta
                          aceitação do cartão, conferindo em sua apresentação a
                          data de validade, autenticidade e assinatura do
                          titular, nos termos do contrato de afiliação aos
                          sistemas das administradoras de cartão de crédito.
                        </p>
                      </td>

                      <td
                        width="50%"
                        valign="top"
                        style="
                          text-align: justify;
                          font-size: 10px;
                          border-color: #eee;
                          color: #999;
                          border-width: 0px 0px 0px 0;
                          border-style: solid;
                          ppadding: 5px 0px 5px 20px;
                        "
                      >
                        <p>
                          3) Esta autorização é válida por 10 dias e sua
                          transmissão por fax é permitida apenas para agilizar o
                          processo de venda. Em caso de contestação por parte do
                          portador, o estabelecimento é responsável pela
                          apresentação deste original devidamente preenchido e
                          assinado, cópia frente e verso do cartão, cópia de um
                          documento ofi cial (emitido por um Órgão
                          Federal/Estadual/Municipal) que comprove a identidade
                          do portador, cópia dos bilhetes/vouchers e cópia do
                          comprovante de venda.
                        </p>

                        <p>
                          Caso os serviços sejam prestados em nome de outras
                          pessoas, além do titular do cartão, seus nomes deverão
                          ser relacionados acima, para maior segurança do
                          portador, ressaltando que a assinatura do portador do
                          cartão neste documento é obrigatória.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div style="page-break-after: always"></div>
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "CreditCardDocument",
  props: {
    page: Object,
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      creditCardRows: [],
      contractedServices: [],
      travellers: [],
      contractedProducts: "",
    };
  },
  mounted() {
    this.creditCardRows =
      this.contract.meta.credit_card_rows != undefined
        ? JSON.parse(this.contract.meta.credit_card_rows)
        : [];

    this.contractedServices =
      this.contract.meta.contracted_services != undefined
        ? JSON.parse(this.contract.meta.contracted_services)
        : [];

    this.travellers =
      this.contract.meta.travellers_rows != undefined
        ? JSON.parse(this.contract.meta.travellers_rows)
        : [];

    this.contractedServices.forEach((product) => {
      if (product == "hotel") this.contractedProducts += `Hotel, `;
      if (product == "flight") this.contractedProducts += `Passagem Aérea, `;
      if (product == "service") this.contractedProducts += `Outros, `;
    });
  },
  methods: {
    cardData(id) {
      let cardNumber = this.contract.meta[
        `payment_methods_credit_card_client_number_${id}`
      ]
        ? this.contract.meta[
            `payment_methods_credit_card_client_number_${id}`
          ].split(" ")
        : [];

      return {
        id,
        check: true,
        numeros: {
          primeiro: cardNumber.length > 0 ? cardNumber[0] : "xxxx",
          segundo: cardNumber.length > 0 ? cardNumber[1] : "xxxx",
          terceiro: cardNumber.length > 0 ? cardNumber[2] : "xxxx",
          quarto: cardNumber.length > 0 ? cardNumber[3] : "xxxx",
        },
        expiracao: `${
          this.contract.meta[
            `payment_methods_credit_card_client_month_${id}`
          ] &&
          this.contract.meta[`payment_methods_credit_card_client_year_${id}`] !=
            undefined
            ? `${
                this.contract.meta[
                  `payment_methods_credit_card_client_month_${id}`
                ]
              }/${
                this.contract.meta[
                  `payment_methods_credit_card_client_year_${id}`
                ]
              }`
            : `${
                this.contract.meta[
                  `payment_methods_credit_card_masked_month_${id}`
                ]
              }/${
                this.contract.meta[
                  `payment_methods_credit_card_masked_year_${id}`
                ]
              }`
        }`,
        cvv: `${
          this.contract.meta[`payment_methods_credit_card_client_cvv_${id}`] !=
          undefined
            ? this.contract.meta[`payment_methods_credit_card_client_cvv_${id}`]
            : this.contract.meta[`payment_methods_credit_card_masked_cvv_${id}`]
        }`,
        nome: `${
          this.contract.meta[`payment_methods_credit_card_client_name_${id}`] !=
          undefined
            ? this.contract.meta[
                `payment_methods_credit_card_client_name_${id}`
              ].toUpperCase()
            : this.contract.meta[
                `payment_methods_credit_card_masked_name_${id}`
              ]
        }`,
        bandeira: `${
          this.contract.meta[`payment_methods_credit_card_client_flag_${id}`] !=
          undefined
            ? this.contract.meta[
                `payment_methods_credit_card_client_flag_${id}`
              ].trim()
            : this.contract.meta[
                `payment_methods_credit_card_masked_flag_${id}`
              ]
        }`,
        valor: `${
          this.contract.meta[`payment_methods_credit_card_total_value_${id}`] !=
          undefined
            ? this.formatAnyPricePtBr(
                this.contract.meta[
                  `payment_methods_credit_card_total_value_${id}`
                ]
              )
            : ""
        }`,
        parcelas: `${
          this.contract.meta[`payment_methods_credit_card_installments_${id}`]
        }`,
        primeira_parcela: `${this.formatAnyPricePtBr(
          this.contract.meta[
            `payment_methods_credit_card_first_installment_value_${id}`
          ]
        )}`, //
        demais_parcelas: `${this.formatAnyPricePtBr(
          this.contract.meta[
            `payment_methods_credit_card_installments_value_${id}`
          ]
        )}`,
        taxas: `${
          this.contract.meta[`payment_methods_credit_card_tax_value_${id}`] !=
          undefined
            ? this.formatAnyPricePtBr(
                this.contract.meta[
                  `payment_methods_credit_card_tax_value_${id}`
                ]
              )
            : "<font style='color: transparent'>___</font>"
        }`,
        proprietario: {
          tipo:
            this.contract.meta[`payment_methods_credit_card_owner_${id}`] !=
            undefined
              ? this.contract.meta[`payment_methods_credit_card_owner_${id}`]
              : "client",
          nome:
            this.contract.meta[
              `payment_methods_credit_card_owner_${id}_name`
            ] != undefined
              ? this.contract.meta[
                  `payment_methods_credit_card_owner_${id}_name`
                ].toUpperCase()
              : ``,
          cpf:
            this.contract.meta[`payment_methods_credit_card_owner_${id}_CPF`] !=
            undefined
              ? this.contract.meta[
                  `payment_methods_credit_card_owner_${id}_CPF`
                ]
              : ``,
          telefone:
            this.contract.meta[
              `payment_methods_credit_card_owner_${id}_phone`
            ] != undefined
              ? this.contract.meta[
                  `payment_methods_credit_card_owner_${id}_phone`
                ]
              : ``,
          celular:
            this.contract.meta[
              `payment_methods_credit_card_owner_${id}_mobile_phone`
            ] != undefined
              ? this.contract.meta[
                  `payment_methods_credit_card_owner_${id}_mobile_phone`
                ]
              : ``,
          endereco: {
            rua:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_street`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_street`
                  ].toUpperCase()
                : ``,
            numero:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_number`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_number`
                  ].toUpperCase()
                : ``,
            bairro:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_neighborhood`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_neighborhood`
                  ].toUpperCase()
                : "",
            complement:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_complement`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_complement`
                  ].toUpperCase()
                : "",
            estado:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_state`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_state`
                  ].toUpperCase()
                : "",
            cidade:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_location_city`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_location_city`
                  ].toUpperCase()
                : "",
            cep:
              this.contract.meta[
                `payment_methods_credit_card_owner_${id}_CEP`
              ] != undefined
                ? this.contract.meta[
                    `payment_methods_credit_card_owner_${id}_CEP`
                  ].toUpperCase()
                : "",
          },
        },
        viajantes: this.contract.meta[
          `payment_methods_credit_card_travellers_${id}`
        ]
          ? JSON.parse(
              this.contract.meta[`payment_methods_credit_card_travellers_${id}`]
            )
          : [],
      };
    },
  },
};
</script>

<style>
.contract-table {
  margin: 0 auto;
  width: 793px;
}

.reduce-card {
  zoom: 0.8;
}

.contract-table .a4 {
  background: #fff;
  margin-bottom: 20px;
  height: 1122px;
  border: 1px solid #fff;
}

.contract-table .a4 .td {
  padding: 20px;
  height: 1112px;
  vertical-align: top;
  color: #666;
  font-size: 13px;
}
</style>
