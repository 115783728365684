<template>
  <div>
    <a-input
      class="mb-20"
      placeholder="Buscar elementos..."
      v-model="searchInput"
      allow-clear
    >
      <a-icon slot="prefix" type="search"
    /></a-input>

    <a-row
      v-for="item in filteredElements"
      class="mb-30 columns"
      :gutter="[10, 10]"
      :key="item.section"
    >
      <a-col :span="24">
        <a-divider class="mt-0" orientation="left">
          {{ item.section }}
        </a-divider>
      </a-col>
      <span v-for="(element, index) in item.elements" :key="index">
        <a-col v-if="element.show" :span="8">
          <div
            class="option dotted-phrase"
            :class="element.disabled ? 'readonly' : ''"
            @click="onClickElement(element)"
            :title="element.name"
          >
            <a-icon v-if="element.icon" class="mr-5" :type="element.icon" />
            <i v-if="element.faIcon" :class="element.faIcon" class="mr-5"></i>
            {{ element.name }}
          </div>
        </a-col>
      </span>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    tagsOnly: Boolean,
    isRepeater: Boolean,
  },
  data() {
    return {
      searchInput: "",
      elementsArr: [
        {
          section: "Padrão",
          elements: [
            {
              name: "Texto",
              value: "text",
              icon: "font-size",
              faIcon: "",
              disabled: false,
              isTag: false,
              show: true,
            },
            {
              name: "Título",
              value: "title",
              icon: "",
              faIcon: "fa-solid fa-heading",
              disabled: true,
              isTag: false,
              show: true,
            },
            {
              name: "Imagem",
              value: "image",
              icon: "",
              faIcon: "fa-regular fa-image",
              disabled: false,
              isTag: false,
              show: true,
            },
            {
              name: "Separador",
              value: "separator",
              icon: "minus",
              faIcon: "",
              disabled: false,
              isTag: false,
              show: true,
            },
            {
              name: "HTML",
              value: "html",
              icon: "",
              faIcon: "fa-solid fa-code",
              disabled: false,
              isTag: false,
              show: true,
            },
            {
              name: "Data de Hoje",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[DATA HOJE]",
              isTag: true,
              show: true,
            },
          ],
        },
        {
          section: "Contrato",
          elements: [
            {
              name: "Tabela do Contratante",
              value: "customerTable",
              icon: "",
              faIcon: "fa-solid fa-user",
              disabled: false,
              content: "[TABELA CONTRATANTE]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Pagamentos",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-regular fa-credit-card",
              disabled: false,
              content: "[TABELA DE PAGAMENTOS]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Hotéis",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-hotel",
              disabled: false,
              content: "[TABELA HOTÉIS]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Aéreo",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-plane",
              disabled: false,
              content: "[TABELA AÉREO TRECHOS]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Serviços",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-car-side",
              disabled: false,
              content: "[TABELA SERVIÇOS]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Políticas",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-book",
              disabled: false,
              content: "[TABELA POLÍTICAS]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela de Viajantes",
              value: "paymentTable",
              icon: "",
              faIcon: "fa-solid fa-people-group",
              disabled: false,
              content: "[TABELA VIAJANTES]",
              isTag: true,
              show: true,
            },
            {
              name: "Tabela Terceiros Beneficiários",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-people-group",
              disabled: false,
              content: "[TABELA TERCEIROS BENEFICIÁRIO]",
              isTag: true,
              show: true,
            },
            {
              name: "Cartão Crédito ID",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO ID]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Valor",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO VALOR]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Taxas",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO VALOR TAXAS]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Parcelas",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PARCELAS]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito 1ª Parcela",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PRIMEIRA PARCELA]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Valor Parcelas",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO VALOR PARCELAS]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Titular",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NOME]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Número",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NUMERO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Número 1 a 4",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NUMERO 1-4]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Número 4 a 8",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NUMERO 4-8]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Número 8 a 12",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NUMERO 8-12]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Número 12 a 16",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO NUMERO 12-16]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Mês",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO MES]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Ano",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO ANO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito CVV",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO CVV]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Bandeira",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO BANDEIRA]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Imagem Bandeira",
              value: "...",
              icon: "",
              faIcon: "fa-regular fa-image",
              disabled: false,
              content: "[CARTÃO CREDITO BANDEIRA IMAGEM]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário Nome",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO NOME]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário Endereço",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO ENDEREÇO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário CPF",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO CPF]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário Telefone",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO TELEFONE]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Cartão Crédito Proprietário Celular",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[CARTÃO CREDITO PROPRIETARIO CELULAR]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros ID",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ID]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Valor",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS VALOR]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Rua",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO RUA]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Número",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO NUMERO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Complemento",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO COMPLEMENTO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Bairro",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO BAIRRO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Cidade",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO CIDADE]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço Estado",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO ESTADO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Endereço CEP",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS ENDEREÇO CEP]",
              isTag: true,
              ishow: this.isRepeater,
            },
            {
              name: "Terceiros Telefone",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS TELEFONE]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Celular",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS CELULAR]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Nome",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS NOME]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Sobrenome",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS SOBRENOME]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros E-mail",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS EMAIL]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros Nascimento",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS NASCIMENTO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros CPF",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS CPF]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros RG",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS RG]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros RG Orgão",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS RG ORGÃO]",
              isTag: true,
              show: this.isRepeater,
            },
            {
              name: "Terceiros RG Estado",
              value: "...",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[TERCEIROS RG ESTADO]",
              isTag: true,
              show: this.isRepeater,
            },
          ],
        },
        {
          section: "Empresa/Filial",
          elements: [
            {
              name: "Logo da Filial",
              value: "companyBranchLogo",
              icon: "",
              faIcon: "fa-regular fa-image",
              disabled: false,
              content: "[LOGO FILIAL]",
              isTag: true,
              show: true,
            },
            {
              name: "Nome da Filial",
              value: "companyBranchName",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[NOME FILIAL]",
              isTag: true,
              show: true,
            },
            {
              name: "Empresa CNPJ",
              value: "companyBranchName",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[EMPRESA CNPJ]",
              isTag: true,
              show: true,
            },
            {
              name: "Empresa Nome Fantasia",
              value: "companyBranchName",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[EMPRESA NOME FANTASIA]",
              isTag: true,
              show: true,
            },
            {
              name: "Empresa Razão Social",
              value: "companyBranchName",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[EMPRESA RAZAO SOCIAL]",
              isTag: true,
              show: true,
            },
            {
              name: "Empresa Endereço",
              value: "companyBranchName",
              icon: "",
              faIcon: "fa-solid fa-t",
              disabled: false,
              content: "[EMPRESA ENDEREÇO]",
              isTag: true,
              show: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    filteredElements() {
      if (!this.searchInput && !this.tagsOnly) return this.elementsArr;

      const filteredSections = this.elementsArr.map((section) => {
        const filteredElements = section.elements.filter(
          (element) =>
            (this.tagsOnly ? element.isTag : true) &&
            (this.searchInput
              ? element.name
                  .toLowerCase()
                  .includes(this.searchInput.toLowerCase())
              : true)
        );
        return { ...section, elements: filteredElements };
      });

      return filteredSections.filter((section) => section.elements.length > 0);
    },
  },
  methods: {
    onSerchElement(e) {
      console.log(e.target.value);
      this.searchInput = e.target.value;
    },
    filterElements(arr) {
      const filteredArr = arr.filter((item) => {
        item.section;
        item.elements;
      });

      return this.searchElement ? filteredArr : arr;
    },
    onClickElement(element) {
      if (this.tagsOnly) {
        this.copyToClipboard(element.content);
      } else {
        this.selectElement(element);
      }
    },
    copyToClipboard(content) {
      const textarea = document.createElement("textarea");
      textarea.value = content;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.info("Copiado");
      this.$emit("closeInsertElementModal");
    },
    selectElement(element) {
      if (element.value === "text")
        this.$emit("addSelectedElement", {
          type: "text",
          content: "Alterar texto",
          css: {},
          isTag: element.isTag,
        });

      if (element.value === "title")
        this.$emit("addSelectedElement", {
          type: "title",
          content: "Alterar título",
          css: {},
          isTag: element.isTag,
        });

      if (element.value === "image")
        this.$emit("addSelectedElement", {
          type: "image",
          content: "IMAGEM",
          css: {},
          isTag: element.isTag,
        });

      if (element.value === "separator")
        this.$emit("addSelectedElement", {
          type: "separator",
          //content: "<div class='separator' style='color: transparent'>-</div>",
          content: "<br>",
          css: {},
          isTag: element.isTag,
        });

      if (element.value === "html")
        this.$emit("addSelectedElement", {
          type: "html",
          content: "<div>Insira o HTML<div>",
          css: {},
          isTag: element.isTag,
        });

      if (element.isTag)
        this.$emit("addSelectedElement", {
          type: element.value,
          content: element.content,
          css: {},
          isTag: element.isTag,
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.columns
  .option
    display: block
    width: 100%
    background: #fff
    border: 1px solid #ddd
    cursor: pointer
    font-weight: 600
    padding:  10px
    text-align: center
    font-size: 12px
  .option.readonly
    color: #bbb
    cursor: no-drop !important
</style>
