<template>
  <div>
    <div class="element-wrapper text">
      <div class="content">
        <div v-html="renderTags(content, contract, previewDoc, [], itemId)" />
      </div>
      <DocumentElementActions
        :editAction="true"
        :cloneAction="true"
        :deleteAction="true"
        @editElement="editElement"
        @deleteElement="deleteElement"
        @cloneElement="cloneElement"
        class="actions"
      />
    </div>

    <aModal
      class="doc-builder-modal"
      :footer="false"
      v-model="openEdit"
      @ok="openEdit = false"
      width="800px"
    >
      <template #title>
        <aRow type="flex" align="middle">
          <aCol :span="8"> <i class="fa-solid fa-code mr-5"></i> HTML </aCol>
          <aCol :span="8">
            <a-button
              class="white f12"
              type="link"
              @click="openElementsModal = true"
            >
              <i class="fa-solid fa-puzzle-piece mr-5"></i>
              <b>Selecionar tags</b>
            </a-button>
          </aCol>
        </aRow>
      </template>
      <DocumentHtmlElementEdit
        @updateElement="updateElement"
        :content="content"
      />
    </aModal>

    <aModal
      class="doc-builder-modal"
      :footer="false"
      v-model="openElementsModal"
      @ok="openElementsModal = false"
      width="960px"
    >
      <template #title>
        <i class="fa-solid fa-puzzle-piece mr-5"></i> SELECIONAR TAGS
      </template>
      <DocumentInsertElementModal
        :tagsOnly="true"
        :isRepeater="isRepeater"
        @addSelectedElement="addSelectedElement"
        @closeInsertElementModal="openElementsModal = false"
      />
    </aModal>
  </div>
</template>

<script>
import renderDocBuilderTags from "@/components/documents/mixins/renderDocBuilderTags";

import DocumentElementActions from "./DocumentElementActions.vue";
import DocumentHtmlElementEdit from "./DocumentHtmlElementEdit.vue";
import DocumentInsertElementModal from "../../modals/DocumentInsertElementModal.vue";

export default {
  props: {
    content: String,
    cIndex: Number,
    eIndex: Number,
    contract: Object,
    previewDoc: Boolean,
    itemId: String,
    isRepeater: Boolean,
  },
  components: {
    DocumentElementActions,
    DocumentHtmlElementEdit,
    DocumentInsertElementModal,
  },
  mixins: [renderDocBuilderTags],
  data() {
    return {
      openEdit: false,
      openElementsModal: false,
    };
  },
  methods: {
    addSelectedElement() {},
    editElement() {
      this.openEdit = true;
    },
    cloneElement() {
      this.$emit("cloneElement", this.eIndex, this.cIndex);
    },
    deleteElement() {
      this.$emit("deleteElement", this.eIndex, this.cIndex);
    },
    updateElement(newContent) {
      this.content = newContent;
      this.$emit("updateElement", this.eIndex, this.cIndex, newContent);
    },
  },
};
</script>

<style lang="sass" scoped>
.element-wrapper:hover
  .actions
    display: block
.element-wrapper
  position: relative
  .content
    cursor: grab
    background: #efeff0
    clear: both
    display: block
    float: left
    width: 100%
    margin-bottom: 1px
  .actions
    position: absolute
    background: #fff
    font-size: 14px
    padding: 0 5px
    right: 0
    top: 0
    display: none
</style>
