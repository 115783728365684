<template>
  <section>
    <div v-for="(page, index) in docStructure" :key="index">
      <div
        v-if="docStructure.length === 1"
        style="page-break-after: always; font-size: 0"
      >
        .
      </div>
      <div
        v-if="docStructure.length > 1 && index !== 0"
        style="page-break-after: always; font-size: 0"
      >
        .
      </div>

      <div class="page">
        <div
          class="page-content prev-mode"
          :span="24"
          :style="`padding: ${page?.css?.padding}; font-size: ${page?.css?.fontSize};`"
        >
          <aRow>
            <aCol class="sections" :span="24">
              <aRow v-for="(section, sIndex) in page.sections" :key="sIndex">
                <DocumentColumnsSection
                  :itemId="page.itemId"
                  :pageData="page"
                  :previewDoc="true"
                  :pIndex="index"
                  :sIndex="sIndex"
                  :columns="section.columns"
                  :type="section.type"
                  :docStructure="docStructure"
                  :contract="contract"
                  :contractPolicies="contractPolicies"
                />
              </aRow>
            </aCol>
          </aRow>
        </div>
      </div>
      <div class="mb-20" :span="24"></div>
    </div>
  </section>
</template>

<script>
import DocumentColumnsSection from "@/components/documents/builder/sections/DocumentColumnsSection.vue";
import documentMixins from "@/components/documents/mixins/documentMixins";

export default {
  name: "DocRenderView",
  props: {
    id: String,
    contract: Object,
    contractPolicies: Array,
  },
  components: {
    DocumentColumnsSection,
  },
  mixins: [documentMixins],
  data() {
    return {
      docStructure: [],
    };
  },
  async mounted() {
    await this.getDocumentById(this.id).then(({ data }) => {
      if (
        data.is_repeater == 1 &&
        data.repeater_type === "credit-card-contract"
      ) {
        const creditCardRows =
          this.contract.meta.credit_card_rows !== undefined
            ? JSON.parse(this.contract.meta.credit_card_rows)
            : [];

        if (creditCardRows.length) {
          let arr = [];
          creditCardRows.forEach((card) => {
            arr.push({ ...data.html[0], itemId: card.id });
          });

          this.docStructure = arr;
        }
      } else if (
        data.is_repeater == 1 &&
        data.repeater_type === "third-party-contract"
      ) {
        const thirdPartyRows =
          this.contract.meta.third_party_payment_rows !== undefined
            ? JSON.parse(this.contract.meta.third_party_payment_rows)
            : [];

        if (thirdPartyRows.length) {
          let arr = [];
          thirdPartyRows.forEach((tParty) => {
            arr.push({ ...data.html[0], itemId: tParty.id });
          });

          this.docStructure = arr;
        }
      } else {
        this.docStructure = data.html;
      }
    });
  },
};
</script>

<style lang="sass" scoped>
.page-content
  cursor: grab
  position: relative
  overflow: hidden
  width: 802px
  height: 1440px
  background: #FFF
  box-shadow: 0 0 90px #cbcbcb
</style>
