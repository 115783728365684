import { render, staticRenderFns } from "./ViewContractPreviewDocumentsSectionV2.vue?vue&type=template&id=107d4bc8&scoped=true&"
import script from "./ViewContractPreviewDocumentsSectionV2.vue?vue&type=script&lang=js&"
export * from "./ViewContractPreviewDocumentsSectionV2.vue?vue&type=script&lang=js&"
import style0 from "./ViewContractPreviewDocumentsSectionV2.vue?vue&type=style&index=0&id=107d4bc8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107d4bc8",
  null
  
)

export default component.exports