<template>
  <div>
    <aRow class="box-elements outer-box mb-10">
      <aCol class="a-right actions" :span="24">
        <DocumentSectionActions
          :editAction="false"
          :cloneAction="false"
          :deleteAction="true"
          @editSection="editSection"
          @deleteSection="deleteSection"
          @cloneSection="cloneSection"
        />
      </aCol>
      <aCol :span="24">
        <aRow :gutter="20">
          <!-- <draggable v-model="columns" group="columns"> -->
          <aCol
            :class="previewDoc ? 'dont-grab' : ''"
            v-for="(column, cIndex) in columns"
            :key="cIndex"
            :span="cols"
          >
            <span class="type"> {{ type | formatColName }}</span>
            <aRow>
              <aCol class="box-elements" :span="24">
                <aRow>
                  <aCol :span="24">
                    <draggable
                      v-model="column.elements"
                      group="elements"
                      :class="previewDoc ? 'dont-grab' : ''"
                    >
                      <aRow
                        v-for="(element, eIndex) in column.elements"
                        :key="eIndex"
                      >
                        <DocumentTagElement
                          v-if="element.isTag"
                          :content="element.content ? element.content : '...'"
                          :eIndex="eIndex"
                          :cIndex="cIndex"
                          :itemId="itemId"
                          :previewDoc="previewDoc"
                          :contract="contract"
                          :contractPolicies="contractPolicies"
                          @updateElement="updateElement"
                          @deleteElement="deleteElement"
                          @cloneElement="cloneElement"
                        />

                        <DocumentTextElement
                          v-if="element.type === 'text'"
                          :content="element.content ? element.content : '...'"
                          :cIndex="cIndex"
                          :eIndex="eIndex"
                          :contract="contract"
                          :itemId="itemId"
                          :previewDoc="previewDoc"
                          :isRepeater="isRepeater"
                          @updateElement="updateElement"
                          @deleteElement="deleteElement"
                          @cloneElement="cloneElement"
                        />

                        <DocumentSeparatorElement
                          v-if="element.type === 'separator'"
                          :content="element.content ? element.content : '<hr>'"
                          :cIndex="cIndex"
                          :eIndex="eIndex"
                          :contract="contract"
                          :previewDoc="previewDoc"
                          @updateElement="updateElement"
                          @deleteElement="deleteElement"
                          @cloneElement="cloneElement"
                        />

                        <DocumentImageElement
                          v-if="element.type === 'image'"
                          :content="element.content ? element.content : '...'"
                          :cIndex="cIndex"
                          :eIndex="eIndex"
                          @updateElement="updateElement"
                          @deleteElement="deleteElement"
                          @cloneElement="cloneElement"
                        />

                        <DocumentHtmlElement
                          v-if="element.type === 'html'"
                          :content="element.content ? element.content : '...'"
                          :cIndex="cIndex"
                          :eIndex="eIndex"
                          :itemId="itemId"
                          :contract="contract"
                          :previewDoc="previewDoc"
                          :isRepeater="isRepeater"
                          @updateElement="updateElement"
                          @deleteElement="deleteElement"
                          @cloneElement="cloneElement"
                        />
                      </aRow>

                      <aRow v-if="column.elements.length == 0">
                        <aCol
                          class="empty-space"
                          :span="24"
                          @click="onClickOpenElementsModal(cIndex)"
                        >
                          <i class="fa-solid fa-puzzle-piece mr-5"></i> ADD OU
                          ARRASTE
                        </aCol>
                      </aRow>
                    </draggable>
                  </aCol>
                  <aCol
                    v-if="column.elements.length > 0"
                    @click="onClickOpenElementsModal(cIndex)"
                    class="add-thing"
                    :span="24"
                  >
                    <i class="fa-solid fa-puzzle-piece mr-5"></i> ADD ELEMENTO
                  </aCol>
                </aRow>
              </aCol>
            </aRow>
          </aCol>
          <!-- </draggable> -->
        </aRow>
      </aCol>
    </aRow>

    <aModal
      class="doc-builder-modal"
      :footer="false"
      v-model="openElementsModal"
      @ok="openElementsModal = false"
      width="960px"
    >
      <template #title>
        <i class="fa-solid fa-puzzle-piece mr-5"></i> INSERIR ELEMENTOS
      </template>
      <DocumentInsertElementModal
        :isRepeater="isRepeater"
        @addSelectedElement="addSelectedElement"
      />
    </aModal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DocumentInsertElementModal from "../../modals/DocumentInsertElementModal.vue";
import DocumentHtmlElement from "../elements/DocumentHtmlElement.vue";
import DocumentImageElement from "../elements/DocumentImageElement.vue";
import DocumentSectionActions from "../elements/DocumentSectionActions.vue";
import DocumentSeparatorElement from "../elements/DocumentSeparatorElement.vue";
import DocumentTextElement from "../elements/DocumentTextElement.vue";
import DocumentTagElement from "../elements/DocumentTagElement.vue";

export default {
  name: "DocumentColumnsSection",
  props: {
    itemId: String,
    columns: Array,
    section: Object,
    sIndex: Number,
    pIndex: Number,
    type: String,
    previewDoc: Boolean,
    contract: Object,
    contractPolicies: Array,
    isRepeater: Boolean,
  },
  components: {
    draggable,
    DocumentTextElement,
    DocumentInsertElementModal,
    DocumentSectionActions,
    DocumentHtmlElement,
    DocumentImageElement,
    DocumentTagElement,
    DocumentSeparatorElement,
  },
  data() {
    return {
      openElementsModal: false,
      selectedColumn: undefined,
      cols: 12,
    };
  },
  mounted() {
    if (this.type === "col1") this.cols = 24;
    if (this.type === "col2") this.cols = 12;
    if (this.type === "col3") this.cols = 8;
    if (this.type === "col4") this.cols = 6;
  },
  filters: {
    formatColName(col) {
      let theName = "";
      if (col === "col1") theName = "1/1";
      if (col === "col2") theName = "1/2";
      if (col === "col3") theName = "1/3";
      if (col === "col4") theName = "1/4";
      return theName;
    },
  },
  methods: {
    deleteSection() {
      this.$emit("deleteSection", this.pIndex, this.sIndex);
    },
    cloneSection() {},
    editSection() {},
    onClickOpenElementsModal(cIndex) {
      this.openElementsModal = true;
      this.selectedColumn = cIndex;
    },
    addSelectedElement(elementObj) {
      this.columns[this.selectedColumn].elements.push(elementObj);
      this.openElementsModal = false;
      this.selectedColumn = undefined;
    },
    updateElement(eIndex, cIndex, newContent) {
      this.columns[cIndex].elements[eIndex].content = newContent;
    },
    deleteElement(eIndex, cIndex) {
      this.columns[cIndex].elements.splice(eIndex, 1);
    },
    cloneElement(eIndex, cIndex) {
      let elementToClone = this.columns[cIndex].elements[eIndex];
      elementToClone = JSON.stringify(elementToClone);
      this.columns[cIndex].elements.splice(
        eIndex + 1,
        0,
        JSON.parse(elementToClone)
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.dont-grab
  pointer-events: none
  cursor: pointer
.outer-box
  .actions, .add-thing
    display: none
.outer-box:hover
  .actions, .add-thing
    display: block !important
.box-elements
  border: 1px solid #ddd
  padding: 14px 5px 5px
  position: relative
  .type
    position: absolute
    background: #eee
    width: 25px
    text-align: center
    color: #666
    font-size: 8px
    top: 0
    z-index: 2
    opacity: 0.5
  .empty-space
    text-align: center
    padding: 8px 14px
    border: 2px dashed #eee
    cursor: pointer
    color: #ddd
    margin: 5px 0
    font-weight: 500
  .add-thing
    text-align: center
    padding: 8px 14px
    border: 2px solid #ddd
    cursor: pointer
    color: #aaa
    margin: 5px 0
    font-weight: 500
</style>
