<template>
  <div>
    <aAnchor
      class="travel-anchor order-docs"
      :class="drag ? 'dragging' : ''"
      @change="smoothScroll"
    >
      <div class="title">Documentos</div>

      <draggable
        v-model="contractPages"
        @start="drag = true"
        @end="drag = false"
        @change="updtPagesPosition"
      >
        <a-anchor-link
          v-for="(page, index) in contractPages"
          :key="index"
          :href="`#doc-${page.id}`"
        >
          <template slot="title">
            <a-checkbox
              class="mr-10"
              v-model="contractPages[index]['check']"
              style="font-size: 13px; font-weight: 500; position: relative"
              @change="updateContractPages"
            >
            </a-checkbox>

            <a-icon type="menu" class="mr-5" />
            {{ page.title.replace("(SISTEMA) VR - ", "") }}
            <a-tooltip
              v-if="page.id == 9"
              placement="top"
              title="Ver dados do cartão"
            >
              <a-button
                type="primary"
                class="ml-5"
                @click="viewCreditCardData"
                shape="circle"
                size="small"
                icon="eye"
              />
            </a-tooltip>
          </template>

          <a-anchor-link
            v-for="(subpage, index) in page.subpages"
            :key="index"
            :href="`#doc-${page.id}-${subpage.id}`"
          >
            <template slot="title">
              <a-checkbox
                class="mr-10"
                v-model="page.subpages[index]['check']"
                style="font-size: 13px; font-weight: 500; position: relative"
              >
              </a-checkbox>

              {{
                `${page.title.replace("(SISTEMA) VR - ", "")} #${subpage.id}`
              }}
            </template>
          </a-anchor-link>
        </a-anchor-link>
      </draggable>
    </aAnchor>

    <aForm
      v-if="editDocuments"
      :form="documentsForm"
      @submit="submitDocumentsForm"
    >
      <a-form-item class="mt-20 mb-10 contract-docs">
        <a-select
          class="travel-input f12"
          show-search
          placeholder="Selecione os documentos..."
          mode="multiple"
          v-decorator="[
            'contract_documents',
            {
              initialValue: selectedDocs,
              rules: [
                {
                  required: true,
                  message: 'Selecione os documentos que serão gerados.',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of documentsList"
            :key="index"
            :value="item.id"
          >
            #{{ item.id }} - {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <div class="a-center">
        <a-button
          :loading="loadingDocumentsForm"
          type="primary"
          ghost
          html-type="submit"
        >
          Salvar documentos
        </a-button>
      </div>
    </aForm>

    <aModal
      :visible="showPasswordModal"
      :footer="false"
      :width="300"
      @cancel="showPasswordModal = false"
    >
      <template #title> <aIcon type="lock" /> Verificação </template>
      <LoginFormModal
        v-if="showPasswordModal"
        :updateStorageData="false"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import jq from "jquery";
import LoginFormModal from "../../login/LoginFormModal.vue";

export default {
  name: "ViewContractDocNavSection",
  props: {
    contract: Object,
    editDocuments: Boolean,
    contractPages: Array,
  },
  components: { draggable, LoginFormModal },
  data() {
    return {
      loadingDocuments: false,
      loadingDocumentsForm: false,
      showPasswordModal: false,
      getCardDataLoading: false,
      authCode: "",
      userPassword: "",
      documentsList: [],
      documentsForm: this.$form.createForm(this),
      selectedDocs: [],
      drag: false,
    };
  },
  mounted() {
    this.getDocuments();
    this.selectedDocs = this.contract.meta.contract_documents
      ? JSON.parse(this.contract.meta.contract_documents)
      : [];
  },
  created() {
    this.$on("openPasswordModal", this.handleEvent);
  },
  methods: {
    verificationSuccessfully(verificationData) {
      this.showPasswordModal = false;
      this.getCardDataLoading = true;

      this.$hayaApi
        .post(`/contracts/credit-cards-data`, {
          ...verificationData,
          contractId: this.contract.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);

          this.contract.meta = {
            ...this.contract.meta,
            ...data.card,
          };

          this.$emit("updateContractMeta", this.contract.meta);
        })
        .finally(() => {
          this.getCardDataLoading = false;
        });
    },
    handleEvent() {
      this.showPasswordModal = true;
    },
    getDocuments() {
      this.loadingDocuments = true;
      this.$http
        .get(
          `/document/list?page=1&per_page=1000&company_branch_id=${this.contract.company_branch_id}&status=1&is_general=1&html=no`
        )
        .then(({ data }) => {
          this.documentsList = data.data;

          this.documentsList.push({
            id: "10",
            name: `Anexo II - Terceiros `,
            company: "all",
            is_general: 1,
          });

          this.documentsList.push({
            id: "9",
            name: `(SISTEMA) Autorização de Débito`,
            company: "all",
            is_general: 1,
          });

          this.documentsForm.setFieldsValue({
            contract_documents: this.contract.meta.contract_documents
              ? JSON.parse(this.contract.meta.contract_documents)
              : [],
          });

          this.loadingDocuments = false;
        });
    },
    updateContractPages() {
      this.$emit("updateContractPages", this.contractPages);
    },
    updtPagesPosition() {
      this.$emit("updateContractPages", this.contractPages);

      let selectedDocs = this.contractPages.map((doc) => {
        return doc.id;
      });

      this.selectedDocs = selectedDocs;
    },
    viewCreditCardData() {
      this.showPasswordModal = true;
      this.userPassword = " ";
      setTimeout(() => {
        this.userPassword = "";
      }, 400);
    },
    submitDocumentsForm(e) {
      e.preventDefault();
      this.documentsForm.validateFields((err, values) => {
        if (!err) {
          this.loadingDocumentsForm = true;
          this.$http
            .post("/contract/update-documents", {
              contract_documents: JSON.stringify(values.contract_documents),
              id: this.contract.id,
              changed_by: this.$store.state.userData.id,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingDocumentsForm = false;
              window.open("/contracts/view/" + this.contract.id, "_self");
            });
        }
      });
    },
    smoothScroll(e) {
      if (e) {
        let elementTop = document.getElementById(e.replace("#", "")).offsetTop;

        jq(".document-viewer").animate(
          {
            scrollTop: elementTop,
          },
          1000
        );
      }
    },
  },
};
</script>

<style lang="sass">
.travel-anchor
  .ant-anchor-wrapper
    .ant-anchor-link
      max-height: 160px
      overflow: auto
      white-space: nowrap
      text-overflow: ellipsis
      max-width: 300px
.contract-docs
  li.ant-select-selection__choice
    max-width: 290px !important
</style>
