<template>
  <div>
    <div id="scroll-top"></div>
    <div id="the-outer-document">
      <DocRenderPages
        v-if="contract.id"
        :documents="documents"
        :contract="contract"
      />
    </div>
    <span id="to-top"><a-icon type="up-circle" @click="toTop" /></span>
  </div>
</template>

<script>
import jq from "jquery";
import DocRenderPages from "@/components/documents/sections/DocRenderPages.vue";

export default {
  name: "ViewContractPreviewDocumentsSection",
  components: {
    DocRenderPages,
  },
  props: {
    contract: Object,
    documents: Array,
  },
  data() {
    return {
      loadingDocuments: false,
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      this.loadingDocuments = true;

      let documents =
        this.contract.meta.contract_documents != undefined
          ? JSON.parse(this.contract.meta.contract_documents)
          : [];

      this.$http
        .get(`/document/list?page=1&per_page=100&id=${documents}`)
        .then(({ data }) => {
          this.contractPages = data.data.map((doc) => {
            return { ...doc, check: true };
          });

          this.systemDocs();
          this.$emit("updateContractPages", this.contractPages);
          this.loadingDocuments = false;
        });
    },
    toTop() {
      jq(".document-viewer").animate(
        {
          scrollTop: document.getElementById("scroll-top").offsetTop - 60,
        },
        500
      );
    },
    systemDocs() {
      let docs = this.contract.meta.contract_documents
        ? JSON.parse(this.contract.meta.contract_documents)
        : [];

      if (docs.length > 0) {
        docs.forEach((document) => {
          if (
            document == "7" ||
            document == "8" ||
            document == "9" ||
            document == "10"
          ) {
            let title = "";
            let subpages = [];

            let creditCards =
                this.contract.meta.credit_card_rows != undefined
                  ? JSON.parse(this.contract.meta.credit_card_rows)
                  : [],
              mapedCreditCards = [];

            if (creditCards.length > 0) {
              mapedCreditCards = creditCards.map((cCard) => {
                return { ...cCard, check: true };
              });
            }

            if (document == "9") {
              title = "Aut. de Débito";
              subpages = mapedCreditCards; // this.contrato.pagamento.credito.cartao
            }

            let thirdParty =
                this.contract.meta.third_party_payment_rows != undefined
                  ? JSON.parse(this.contract.meta.third_party_payment_rows)
                  : [],
              mapedThirdParty = [];

            if (thirdParty.length > 0) {
              mapedThirdParty = thirdParty.map((tParty) => {
                return { ...tParty, check: true };
              });
            }

            if (document == "10") {
              title = "Anexo II - Terceiros";
              subpages = mapedThirdParty; //  this.contrato.pagamento.terceiros.lista
            }

            if (document == "8") {
              title = "VR - Anexo - Produtos";
            }

            if (document == "7") {
              title = "VR - Contrato 2021";
            }

            this.contractPages.push({
              id: document,
              title: title,
              subtitle: "",
              data: [],
              check: true,
              subpages,
            });
          }
        });
      }

      // SORT PAGES BASED ON SELECTED DOCS

      if (this.contract.meta.contract_documents != undefined) {
        const sorter = (a, b) => {
          return (
            JSON.parse(this.contract.meta.contract_documents).indexOf(a.id) -
            JSON.parse(this.contract.meta.contract_documents).indexOf(b.id)
          );
        };

        this.contractPages.sort(sorter);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#to-top
  position: absolute
  bottom: 10px
  right: 30px
  font-size: 35px
  cursor: pointer
  color: #fff
  opacity: 0.3
  transition: .3s
  &:hover
    opacity: 1
#the-outer-document
  max-width: 793px
  margin: 0 auto
</style>
